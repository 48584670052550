import * as types from "../actionTypes";

const initialState = {
  loading: true,
  homepageData: {},
};

export function homepageData(state = initialState, action) {
  switch (action.type) {
    case types.SET_HOMEPAGE_DATA:
      return { loading: false, homepageData: action.payload };

    default:
      return state;
  }
}

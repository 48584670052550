export const FETCH_ARTICLES = "FETCH_ARTICLES";
export const SET_FILTER = "SET_FILTER";
export const SET_ACTIVE_ARTICLE = "SET_ACTIVE_ARTICLE";
export const UNLOAD_ARTICLE = "UNLOAD_ARTICLE";
export const SET_ACTIVE_STORY = "SET_ACTIVE_STORY";
export const UNLOAD_STORY = "UNLOAD_STORY";
export const SET_CURRENT_CATEGORIES = "SET_CURRENT_CATEGORIES";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_STORIES = "FETCH_STORIES";
export const FETCH_FAQ = "FETCH_FAQ";
export const EMPTYSEARCHRESULTS = "EMPTYSEARCHRESULTS";
export const SEARCHLOOKUP = "SEARCHLOOKUP";
export const CURRENT_SEARCHTERM = "CURRENT_SEARCHTERM";
export const IS_SEARCHING = "IS_SEARCHING";
export const SEARCH_VIEW_VISIBLE = "SEARCH_VIEW_VISIBLE";
export const SEARCH = "SEARCH";
export const FETCH_INTRODUCTION = "FETCH_INTRODUCTION";
export const SET_HOMEPAGE_DATA = "SET_HOMEPAGE_DATA";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const UNLOAD_PAGE = "UNLOAD_PAGE";

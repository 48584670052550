import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { searchLookup } from "../redux/actions";

import Faq from "../components/faq";
import ThumbnailTegel from "./thumbnailTegel";
import Article from "./article";
import Sidebar from "./sidebar";
import ArticleHeader from "./svgComponents/articleHeader";
import SearchResults from "./searchResults";
import CijferIndexFilterContent from "./cijferIndexFilterContent";
import Button from "./button";

function CijferIndex(props) {
  const dispatch = useDispatch();
  const watchElementRef = useRef(null);

  /* FILTER */
  const [filter, setFilter] = useState("");

  // LOAD TERMS FROM URL PATH
  const match = useRouteMatch();

  const [sidebarFooterVisible, setVisibiltySidebarFooter] = useState(false);

  useEffect(() => {
    //reset scroll position if route changes
    window.scrollTo(0, 0);
  }, [match]);

  let terms = [];
  if (match.params.terms) {
    terms = match.params.terms.split("/"); //substr haalt eerste slash weg
    if (terms.length && terms[terms.length - 1] == "") {
      terms.splice(-1, 1); // laatste is leeg ivm trailing slash
    }
  }
  let jaarverslag = null;
  if (terms.length > 0) {
    jaarverslag = terms[0];
  }

  let currentlyActiveTerm = terms[terms.length - 1];

  const { categories, loading: categoriesAreLoading } = useSelector((state) => {
    return state.categories;
  });

  if (categoriesAreLoading) {
    return <></>;
  }

  /* ANIMATION */
  const childrenVariants = {
    hide: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        delay: 0.5,
        when: "beforeChildren",
        delayChildren: 0,
        staggerChildren: 0.1,
      },
    },
    exit: {
      opacity: 0.5,
    },
  };

  const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

  const thumbnailVariants = {
    initial: { scale: 0.9, opacity: 0 },
    enter: { scale: 1, opacity: 1, transition },
  };

  /* CHILDREN */

  let children =
    categories[currentlyActiveTerm].children &&
    categories[currentlyActiveTerm].children.length
      ? categories[currentlyActiveTerm].children
      : [];
  if (
    children.length === 1 &&
    categories[children[0]["nameSave"]].children &&
    categories[children[0]["nameSave"]].children.length
  ) {
    children = children.concat(
      categories[categories[currentlyActiveTerm].children[0]["nameSave"]]
        .children
    );
  }

  /* FILTER */
  if (filter !== "") {
    children = children.filter(function (e) {
      if (e.name.toLowerCase().includes(filter)) {
        return true;
      }
      return false;
    });
  }

  return (
    <motion.div
      initial={false}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`cijferIndex in-${jaarverslag}`}
    >
      <div>
        <div className="wrapper withSidebar">
          {currentlyActiveTerm && categories[currentlyActiveTerm].children && (
            <CijferIndexFilterContent
              setFilter={setFilter}
              watchElementRef={watchElementRef}
            />
          )}

          <Article
            articleSlug={currentlyActiveTerm}
            articleName={categories[currentlyActiveTerm]["name"]}
            articleType={categories[currentlyActiveTerm]["type"]}
            articleImage={categories[currentlyActiveTerm]["image"]}
            children={categories[currentlyActiveTerm].children}
            setVisibiltySidebarFooter={setVisibiltySidebarFooter}
            parent={categories[categories[currentlyActiveTerm].parent]}
            pathTerms={terms}
            categories={categories}
          />
          {currentlyActiveTerm && categories[currentlyActiveTerm].children && (
            <motion.div
              initial="initial"
              animate="enter"
              exit="exit"
              variants={{
                initial: { y: "100px", opacity: 0 },
                enter: {
                  y: "0px",
                  opacity: 1,
                  transition: {
                    delay: 1.2,
                    // when: "beforeChildren",
                    delayChildren: 1.3,
                    staggerChildren: 0.1,
                  },
                },
              }}
              className="childrenContainer"
            >
              <div
                className="filterVisibilityTester"
                ref={watchElementRef}
              ></div>

              <h4 className="underline">
                <span>
                  In{" "}
                  {categories[currentlyActiveTerm]["type"] !== "jaarverslag"
                    ? categories[currentlyActiveTerm]["type"]
                    : ""}{" "}
                  <span>{categories[currentlyActiveTerm]["name"]}</span>
                </span>
              </h4>
              {children.length === 0 && (
                <motion.div
                  className="noFilterResults"
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  In deze selectie vonden we niks met <i>'{filter}'</i> in haar
                  naam. <br />
                  Uitgebreider zoeken?{" "}
                  <Button
                    text={`Doorzoek alle jaarverslagen op <strong>'${filter}'</strong>`}
                    type="solid"
                    callBackFunction={() => {
                      dispatch(searchLookup(filter));
                    }}
                  />
                </motion.div>
              )}
              <div className="children">
                {children.map((child) => {
                  return (
                    <motion.div
                      variants={{
                        initial: { scale: 0.9, opacity: 0 },
                        enter: { scale: 1, opacity: 1 },
                        exit: {
                          scale: 0.5,
                          opacity: 0,
                          transition: { duration: 1.5 },
                        },
                      }}
                      key={categories[child.nameSave].id}
                      whileHover="hover"
                      // animate="show"
                      // initial="hide"
                    >
                      <ThumbnailTegel displaySize="compact" article={child} />
                    </motion.div>
                  );
                })}
              </div>
            </motion.div>
          )}

          <div>
            <h2 className="faq underline">
              <span>
                Extra informatie bij {categories[jaarverslag]["name"]}
              </span>
            </h2>
            <Faq showCategories={terms} />
          </div>

          {/* <SearchResults /> */}
        </div>
      </div>
    </motion.div>
  );
}

export default CijferIndex;

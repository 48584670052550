import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <footer>
      <div className="wrapper">
        <div>
          <div>
            <h1 className="logo">
              samen
              <br />
              werken aan
              <br />
              kwaliteit
            </h1>
            <div>
              <p>
                In de voorbije jaren en voor de toekomst is 'samen werken aan
                kwaliteit' dé verbindende factor in het werken aan oplossingen.
              </p>
              <p>
                Daar vertellen wij u graag meer over - en nog belangrijker - dat
                maken we graag voor u een heel jaar lang relevant.
              </p>
              {/* <div className="p">
                Bekijk meer op&nbsp;
                <Button
                  // type="solid"
                  text="www.samenwerkenaankwaliteit.nl"
                  // icon="download"
                  callBackFunction={() => {
                    window.location.href = `https://www.samenwerkenaankwaliteit.nl/`;
                  }}
                />
              </div> */}
            </div>
          </div>
          <div>
            <div className="border">
              <p>
                <strong>De Geschillencommissie</strong>
                <br />
                Postadres:
                <br />
                Postbus 90600
                <br />
                2509 LP Den Haag
              </p>
              <p>
                Bezoekadres:
                <br />
                Bordewijklaan 46
                <br />
                2591 XR Den Haag
              </p>
            </div>
          </div>
          <div>
            <div className="border">
              <p>
                Bel: 070 310 53 10
                <br />
                Mail:{" "}
                <a href="mailto:communicatie@degeschillencommissie.nl">
                  communicatie@degeschillencommissie.nl
                </a>
              </p>
              <ul>
                <li>
                  <a
                    target="_blank"
                    href="https://www.degeschillencommissie.nl"
                  >
                    www.degeschillencommissie.nl
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.degeschillencommissiezorg.nl"
                  >
                    www.degeschillencommissiezorg.nl
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.klachtenloket-kinderopvang.nl"
                  >
                    www.klachtenloket-kinderopvang.nl
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.samenwerkenaankwaliteit.nl"
                  >
                    www.samenwerkenaankwaliteit.nl
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://klachtenloket-openbaarvervoer.nl/"
                  >
                    klachtenloket-openbaarvervoer.nl
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="links">
          <Link target="_blank" to="/page/colofon">
            Colofon
          </Link>

          <Link target="_blank" to="/page/privacy-statement">
            Privacy Statement
          </Link>

          <Link target="_blank" to="/page/cookies">
            Cookies
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

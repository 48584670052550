import React from "react";

function FrontpageInfographic({
  type = "jaarverslagen",
  value1 = "",
  value2 = "",
  value3 = "",
}) {
  if (type === "jaarverslagen") {
    return (
      <svg
        width="379"
        height="125"
        viewBox="0 0 379 125"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="infographic"
      >
        <path
          d="M21.8 13.5999L23.83 5.37988H26.48L28.5201 13.5999H27.18L26.74 11.8399H23.58L23.14 13.5999H21.81H21.8ZM24.8601 6.49988L23.84 10.6599H26.47L25.46 6.49988H24.8601Z"
          fill="white"
        />
        <path
          d="M29.1101 13.5999L31.1401 5.37988H33.7901L35.8301 13.5999H34.4901L34.0501 11.8399H30.8901L30.4501 13.5999H29.1201H29.1101ZM32.1701 6.49988L31.1501 10.6599H33.7801L32.7701 6.49988H32.1701Z"
          fill="white"
        />
        <path
          d="M37.1101 13.5999V5.37988H39.4401L41.9401 12.4199H42.1201V5.37988H43.4501V13.5999H41.1701L38.6101 6.53988H38.4401V13.5999H37.1101Z"
          fill="white"
        />
        <path
          d="M48.8101 10.45V9.27H51.1501V13.45C50.9301 13.51 50.5001 13.57 49.8401 13.63C49.1801 13.69 48.7001 13.73 48.4101 13.73C47.1601 13.73 46.3001 13.39 45.8301 12.7C45.3601 12.01 45.1201 10.93 45.1201 9.47C45.1201 8.01 45.3601 6.93 45.8501 6.26C46.3301 5.59 47.1701 5.25 48.3601 5.25C49.0601 5.25 49.8601 5.33 50.7401 5.49L51.1601 5.57L51.1101 6.63C50.1401 6.5 49.2701 6.44 48.5101 6.44C47.7501 6.44 47.2201 6.66 46.9301 7.09C46.6501 7.52 46.5001 8.32 46.5001 9.49C46.5001 10.66 46.6301 11.46 46.9001 11.91C47.1701 12.35 47.7101 12.58 48.5301 12.58C49.1101 12.58 49.5501 12.54 49.8401 12.47V10.47H48.8201L48.8101 10.45Z"
          fill="white"
        />
        <path
          d="M52.9399 13.5999V5.37988H58.1199V6.53988H54.2699V8.86988H57.3999V10.0199H54.2699V12.4199H58.1199V13.5999H52.9399Z"
          fill="white"
        />
        <path
          d="M59.7 13.5999V5.37988H62.02L63.86 11.7999L65.6999 5.37988H68.0299V13.5999H66.69V6.85988H66.51L64.57 13.2699H63.18L61.24 6.85988H61.06V13.5999H59.7299H59.7Z"
          fill="white"
        />
        <path
          d="M70.02 13.5999V5.37988H75.2V6.53988H71.35V8.86988H74.48V10.0199H71.35V12.4199H75.2V13.5999H70.02Z"
          fill="white"
        />
        <path
          d="M81.37 13.5999H76.76V5.37988H78.09V12.4099H81.37V13.5999Z"
          fill="white"
        />
        <path
          d="M85.4201 13.5999H82.5801V5.37988H85.4201C86.6801 5.37988 87.5501 5.69988 88.0101 6.33988C88.4701 6.97988 88.7101 7.97988 88.7101 9.34988C88.7101 10.0499 88.6601 10.6399 88.5701 11.1199C88.4801 11.5999 88.3201 12.0399 88.0801 12.4299C87.6201 13.2099 86.7401 13.5899 85.4201 13.5899V13.5999ZM87.3301 9.34988C87.3301 8.35988 87.2101 7.63988 86.9601 7.19988C86.7101 6.75988 86.2001 6.53988 85.4201 6.53988H83.9101V12.4199H85.4201C86.2101 12.4199 86.7401 12.1299 87.0001 11.5399C87.1301 11.2399 87.2101 10.9299 87.2601 10.5899C87.3101 10.2499 87.3301 9.83988 87.3301 9.33988V9.34988Z"
          fill="white"
        />
        <path
          d="M90.3901 13.5999V5.37988H95.5701V6.53988H91.7201V8.86988H94.8501V10.0199H91.7201V12.4199H95.5701V13.5999H90.3901Z"
          fill="white"
        />
        <path
          d="M99.3301 6.53988V5.37988H104.73V6.65988L100.91 12.1999V12.4299H104.73V13.5899H99.3301V12.3199L103.13 6.77988V6.53988H99.3301Z"
          fill="white"
        />
        <path
          d="M105.6 13.5999L107.63 5.37988H110.28L112.32 13.5999H110.98L110.54 11.8399H107.38L106.94 13.5999H105.61H105.6ZM108.66 6.49988L107.64 10.6599H110.27L109.26 6.49988H108.66Z"
          fill="white"
        />
        <path
          d="M114.94 13.5999H113.61V5.37988H114.94V9.08988L116.16 8.98988L117.84 5.37988H119.36L117.33 9.50988L119.44 13.5999H117.89L116.15 10.1699L114.93 10.2499V13.5999H114.94Z"
          fill="white"
        />
        <path
          d="M120.73 13.5999V5.37988H125.91V6.53988H122.06V8.86988H125.19V10.0199H122.06V12.4199H125.91V13.5999H120.73Z"
          fill="white"
        />
        <path
          d="M127.48 13.5999V5.37988H129.81L132.31 12.4199H132.49V5.37988H133.82V13.5999H131.54L128.98 6.53988H128.81V13.5999H127.48Z"
          fill="white"
        />
        <path
          d="M139.51 114.76H142.63C143.47 114.76 144.1 114.93 144.51 115.27C144.93 115.61 145.13 116.15 145.13 116.9C145.13 117.39 145.05 117.77 144.9 118.05C144.74 118.33 144.5 118.56 144.16 118.75C144.94 119.05 145.34 119.69 145.34 120.66C145.34 122.21 144.47 122.99 142.72 122.99H139.5V114.77L139.51 114.76ZM142.56 115.91H140.84V118.26H142.6C143.38 118.26 143.78 117.86 143.78 117.05C143.78 116.29 143.37 115.91 142.56 115.91ZM142.63 119.39H140.84V121.83H142.65C143.09 121.83 143.42 121.74 143.64 121.55C143.86 121.37 143.97 121.05 143.97 120.6C143.97 120.15 143.84 119.84 143.59 119.66C143.33 119.48 143.01 119.39 142.63 119.39Z"
          fill="white"
        />
        <path
          d="M146.96 122.98V114.76H152.14V115.92H148.29V118.25H151.42V119.4H148.29V121.8H152.14V122.98H146.96Z"
          fill="white"
        />
        <path
          d="M158.6 122.98V119.43H155.05V122.98H153.72V114.76H155.05V118.26H158.6V114.76H159.94V122.98H158.6Z"
          fill="white"
        />
        <path
          d="M161.23 122.98L163.26 114.76H165.91L167.95 122.98H166.61L166.17 121.22H163.01L162.57 122.98H161.24H161.23ZM164.29 115.88L163.27 120.04H165.9L164.89 115.88H164.29Z"
          fill="white"
        />
        <path
          d="M169.23 122.98V114.76H171.56L174.06 121.8H174.24V114.76H175.57V122.98H173.29L170.73 115.92H170.56V122.98H169.23Z"
          fill="white"
        />
        <path
          d="M180.43 122.98H177.59V114.76H180.43C181.69 114.76 182.56 115.08 183.02 115.72C183.48 116.36 183.72 117.36 183.72 118.73C183.72 119.43 183.67 120.02 183.58 120.5C183.49 120.98 183.33 121.42 183.09 121.81C182.63 122.59 181.75 122.97 180.43 122.97V122.98ZM182.34 118.73C182.34 117.74 182.22 117.02 181.97 116.58C181.72 116.14 181.21 115.92 180.43 115.92H178.92V121.8H180.43C181.22 121.8 181.75 121.51 182.01 120.92C182.14 120.62 182.22 120.31 182.27 119.97C182.32 119.63 182.34 119.22 182.34 118.72V118.73Z"
          fill="white"
        />
        <path
          d="M185.4 122.98V114.76H190.58V115.92H186.73V118.25H189.86V119.4H186.73V121.8H190.58V122.98H185.4Z"
          fill="white"
        />
        <path
          d="M196.75 122.98H192.14V114.76H193.47V121.79H196.75V122.98Z"
          fill="white"
        />
        <path
          d="M200.8 122.98H197.96V114.76H200.8C202.06 114.76 202.93 115.08 203.39 115.72C203.85 116.36 204.09 117.36 204.09 118.73C204.09 119.43 204.04 120.02 203.95 120.5C203.86 120.98 203.7 121.42 203.46 121.81C203 122.59 202.12 122.97 200.8 122.97V122.98ZM202.71 118.73C202.71 117.74 202.59 117.02 202.34 116.58C202.09 116.14 201.58 115.92 200.8 115.92H199.29V121.8H200.8C201.59 121.8 202.12 121.51 202.38 120.92C202.51 120.62 202.59 120.31 202.64 119.97C202.69 119.63 202.71 119.22 202.71 118.72V118.73Z"
          fill="white"
        />
        <path
          d="M205.77 122.98V114.76H210.95V115.92H207.1V118.25H210.23V119.4H207.1V121.8H210.95V122.98H205.77Z"
          fill="white"
        />
        <path
          d="M214.71 115.92V114.76H220.11V116.04L216.29 121.58V121.81H220.11V122.97H214.71V121.7L218.51 116.16V115.92H214.71Z"
          fill="white"
        />
        <path
          d="M220.96 122.98L222.99 114.76H225.64L227.68 122.98H226.34L225.9 121.22H222.74L222.3 122.98H220.97H220.96ZM224.02 115.88L223 120.04H225.63L224.62 115.88H224.02Z"
          fill="white"
        />
        <path
          d="M230.31 122.98H228.98V114.76H230.31V118.47L231.53 118.37L233.21 114.76H234.73L232.7 118.89L234.81 122.98H233.26L231.52 119.55L230.3 119.63V122.98H230.31Z"
          fill="white"
        />
        <path
          d="M236.08 122.98V114.76H241.26V115.92H237.41V118.25H240.54V119.4H237.41V121.8H241.26V122.98H236.08Z"
          fill="white"
        />
        <path
          d="M242.84 122.98V114.76H245.17L247.67 121.8H247.85V114.76H249.18V122.98H246.9L244.34 115.92H244.17V122.98H242.84Z"
          fill="white"
        />
        <path
          d="M258.26 13.5999V5.37988H263.44V6.53988H259.59V8.86988H262.72V10.0199H259.59V12.4199H263.44V13.5999H258.26Z"
          fill="white"
        />
        <path d="M265.01 13.5999V5.37988H266.34V13.5999H265.01Z" fill="white" />
        <path
          d="M268.34 13.5999V5.37988H270.67L273.17 12.4199H273.35V5.37988H274.68V13.5999H272.4L269.84 6.53988H269.67V13.5999H268.34Z"
          fill="white"
        />
        <path
          d="M279.54 13.5999H276.7V5.37988H279.54C280.8 5.37988 281.67 5.69988 282.13 6.33988C282.59 6.97988 282.83 7.97988 282.83 9.34988C282.83 10.0499 282.78 10.6399 282.69 11.1199C282.6 11.5999 282.44 12.0399 282.2 12.4299C281.74 13.2099 280.86 13.5899 279.54 13.5899V13.5999ZM281.45 9.34988C281.45 8.35988 281.33 7.63988 281.08 7.19988C280.83 6.75988 280.32 6.53988 279.54 6.53988H278.03V12.4199H279.54C280.33 12.4199 280.86 12.1299 281.12 11.5399C281.25 11.2399 281.33 10.9299 281.38 10.5899C281.43 10.2499 281.45 9.83988 281.45 9.33988V9.34988Z"
          fill="white"
        />
        <path
          d="M287.52 11.0399H285.84V13.5999H284.51V5.37988H287.52C289.38 5.37988 290.32 6.29988 290.32 8.13988C290.32 9.07988 290.09 9.79988 289.62 10.2999C289.15 10.7999 288.45 11.0399 287.53 11.0399H287.52ZM285.84 9.88988H287.51C288.47 9.88988 288.95 9.30988 288.95 8.13988C288.95 7.57988 288.83 7.16988 288.6 6.91988C288.37 6.65988 288 6.53988 287.51 6.53988H285.84V9.89988V9.88988Z"
          fill="white"
        />
        <path
          d="M293.05 10.6699V13.5999H291.72V5.37988H294.84C296.7 5.37988 297.62 6.24988 297.62 7.99988C297.62 9.16988 297.17 9.95988 296.28 10.3799L297.64 13.6099H296.18L294.99 10.6799H293.06L293.05 10.6699ZM296.25 8.00988C296.25 7.02988 295.78 6.52988 294.83 6.52988H293.04V9.51988H294.85C295.33 9.51988 295.68 9.37988 295.91 9.10988C296.13 8.82988 296.25 8.46988 296.25 8.00988Z"
          fill="white"
        />
        <path
          d="M304.83 12.72C304.34 13.39 303.47 13.73 302.23 13.73C300.99 13.73 300.12 13.39 299.63 12.72C299.14 12.05 298.89 10.98 298.89 9.52999C298.89 8.07999 299.14 7.00999 299.64 6.29999C300.14 5.58999 301.01 5.23999 302.24 5.23999C303.47 5.23999 304.34 5.58999 304.83 6.28999C305.32 6.98999 305.57 8.06999 305.57 9.51999C305.57 10.97 305.32 12.04 304.83 12.71V12.72ZM300.67 11.86C300.94 12.33 301.46 12.57 302.23 12.57C303 12.57 303.52 12.34 303.78 11.87C304.05 11.41 304.18 10.63 304.18 9.53999C304.18 8.44999 304.04 7.64999 303.77 7.15999C303.49 6.65999 302.98 6.41999 302.23 6.41999C301.48 6.41999 300.96 6.66999 300.68 7.15999C300.4 7.65999 300.26 8.44999 300.26 9.52999C300.26 10.61 300.4 11.39 300.67 11.86Z"
          fill="white"
        />
        <path
          d="M310.08 13.5999H307.24V5.37988H310.08C311.34 5.37988 312.21 5.69988 312.67 6.33988C313.13 6.97988 313.37 7.97988 313.37 9.34988C313.37 10.0499 313.32 10.6399 313.23 11.1199C313.14 11.5999 312.98 12.0399 312.74 12.4299C312.28 13.2099 311.4 13.5899 310.08 13.5899V13.5999ZM311.99 9.34988C311.99 8.35988 311.87 7.63988 311.62 7.19988C311.37 6.75988 310.86 6.53988 310.08 6.53988H308.57V12.4199H310.08C310.87 12.4199 311.4 12.1299 311.66 11.5399C311.79 11.2399 311.87 10.9299 311.92 10.5899C311.97 10.2499 311.99 9.83988 311.99 9.33988V9.34988Z"
          fill="white"
        />
        <path
          d="M316.32 10.9699C316.32 12.0299 316.88 12.5699 318 12.5699C319.12 12.5699 319.68 12.0399 319.68 10.9699V5.37988H321.02V10.9499C321.02 11.9199 320.77 12.6199 320.26 13.0699C319.75 13.5099 319 13.7399 317.99 13.7399C316.98 13.7399 316.23 13.5199 315.73 13.0699C315.23 12.6299 314.97 11.9199 314.97 10.9499V5.37988H316.31V10.9699H316.32Z"
          fill="white"
        />
        <path
          d="M325.72 13.73C324.52 13.73 323.71 13.4 323.28 12.73C322.85 12.07 322.64 10.97 322.64 9.44998C322.64 7.92998 322.86 6.84998 323.29 6.19998C323.72 5.55998 324.53 5.22998 325.73 5.22998C326.44 5.22998 327.23 5.32998 328.08 5.52998L328.03 6.60998C327.31 6.47998 326.59 6.41998 325.87 6.41998C325.15 6.41998 324.66 6.62998 324.41 7.05998C324.15 7.48998 324.03 8.28998 324.03 9.46998C324.03 10.65 324.15 11.45 324.4 11.88C324.65 12.31 325.13 12.52 325.85 12.52C326.57 12.52 327.3 12.46 328.03 12.34L328.07 13.44C327.25 13.62 326.47 13.7 325.73 13.7L325.72 13.73Z"
          fill="white"
        />
        <path
          d="M328.82 6.55987V5.36987H334.82V6.55987H332.5V13.5899H331.16V6.55987H328.82Z"
          fill="white"
        />
        <path
          d="M336.04 13.5999V5.37988H341.22V6.53988H337.37V8.86988H340.5V10.0199H337.37V12.4199H341.22V13.5999H336.04Z"
          fill="white"
        />
        <path
          d="M342.79 13.5999V5.37988H345.12L347.62 12.4199H347.8V5.37988H349.13V13.5999H346.85L344.29 6.53988H344.12V13.5999H342.79Z"
          fill="white"
        />
        <path
          d="M78.1801 120.41C103.353 120.41 123.76 100.003 123.76 74.83C123.76 49.6569 103.353 29.25 78.1801 29.25C53.007 29.25 32.6001 49.6569 32.6001 74.83C32.6001 100.003 53.007 120.41 78.1801 120.41Z"
          stroke="#93A7C2"
          strokeWidth="8.51"
          fill="#fff"
        />

        <path
          d="M303.72 120.41C328.893 120.41 349.3 100.003 349.3 74.83C349.3 49.6569 328.893 29.25 303.72 29.25C278.547 29.25 258.14 49.6569 258.14 74.83C258.14 100.003 278.547 120.41 303.72 120.41Z"
          stroke="#F4B083"
          strokeWidth="8.51"
          fill="#fff"
        />

        <path
          d="M189.88 95.42C215.053 95.42 235.46 75.0132 235.46 49.84C235.46 24.6669 215.053 4.26001 189.88 4.26001C164.707 4.26001 144.3 24.6669 144.3 49.84C144.3 75.0132 164.707 95.42 189.88 95.42Z"
          stroke="#A7AC81"
          strokeWidth="8.51"
          fill="#fff"
        />

        <path
          d="M7.76993 69.36L6.17993 66.82C7.90993 65.74 9.71993 64.65 11.5499 63.6L13.0499 66.2C11.2499 67.24 9.46993 68.31 7.76993 69.37V69.36ZM16.6799 64.15L15.2399 61.52C17.0599 60.52 18.9199 59.54 20.7799 58.61L22.1299 61.29C20.3099 62.21 18.4699 63.18 16.6799 64.16V64.15ZM25.8699 59.45L24.5799 56.74C26.4699 55.84 28.3799 54.97 30.2799 54.15L31.4699 56.9C29.6099 57.71 27.7299 58.56 25.8699 59.45ZM35.3099 55.29L34.1899 52.51C36.1499 51.72 38.1099 50.97 40.0399 50.28L41.0499 53.1C39.1599 53.78 37.2299 54.52 35.3099 55.29ZM44.9899 51.75L44.0599 48.9C46.0699 48.24 48.0899 47.63 50.0599 47.09L50.8599 49.98C48.9299 50.52 46.9499 51.11 44.9899 51.75ZM54.8899 48.93L54.1799 46.01C56.2299 45.51 58.2999 45.07 60.3199 44.69L60.8699 47.64C58.8999 48.01 56.8899 48.44 54.8799 48.92L54.8899 48.93ZM64.9599 46.97L64.5299 44C66.6299 43.69 68.7299 43.45 70.7799 43.28L71.0299 46.27C69.0399 46.43 66.9999 46.67 64.9599 46.97Z"
          fill="#565D62"
        />
        <path
          d="M184.62 76.1899C182.56 76.1099 180.53 75.9899 178.6 75.8399L178.84 72.8499C180.73 72.9999 182.72 73.1199 184.74 73.1999L184.62 76.1999V76.1899ZM174.6 75.4599C172.55 75.2299 170.53 74.9599 168.61 74.6499L169.09 71.6899C170.96 71.9999 172.92 72.2599 174.93 72.4799L174.6 75.4599ZM164.64 73.9199C162.62 73.5099 160.64 73.0299 158.74 72.5099L159.54 69.6199C161.37 70.1299 163.29 70.5899 165.24 70.9799L164.64 73.9199ZM154.87 71.3399C152.95 70.7099 151.04 69.9899 149.17 69.2099L150.33 66.4399C152.12 67.1899 153.96 67.8799 155.81 68.4899L154.87 71.3399ZM145.48 67.5599C143.75 66.7299 141.99 65.8199 140.1 64.7799L141.55 62.1599C143.39 63.1799 145.1 64.0599 146.77 64.8599L145.48 67.5699V67.5599ZM136.62 62.7899C135.47 62.1099 134.33 61.4399 133.19 60.7599L131.48 59.7399L133.01 57.1599L134.73 58.1799C135.86 58.8499 136.99 59.5299 138.14 60.1999L136.62 62.7899ZM128.07 57.7399C126.2 56.6699 124.5 55.7599 122.89 54.9399L124.24 52.2599C125.9 53.0999 127.65 54.0399 129.56 55.1299L128.07 57.7299V57.7399ZM119.36 53.2699C117.54 52.4599 115.77 51.7499 113.93 51.0999L114.93 48.2699C116.84 48.9499 118.69 49.6799 120.58 50.5199L119.36 53.2599V53.2699ZM110.23 49.8999C108.4 49.3599 106.49 48.8699 104.56 48.4499L105.2 45.5199C107.21 45.9599 109.19 46.4599 111.08 47.0299L110.23 49.9099V49.8999ZM100.72 47.6999C98.8501 47.3799 96.8901 47.0899 94.9001 46.8599L95.2501 43.8799C97.3001 44.1199 99.3101 44.4099 101.24 44.7399L100.73 47.6999H100.72ZM90.9901 46.4799C89.1001 46.3199 87.1201 46.1999 85.1001 46.1199L85.2201 43.1199C87.2801 43.1999 89.3101 43.3299 91.2401 43.4899L90.9901 46.4799Z"
          fill="#565D62"
        />
        <path
          d="M198.76 76.2L198.65 73.2C200.64 73.13 202.59 73.02 204.45 72.87L204.68 75.86C202.78 76 200.79 76.12 198.76 76.19V76.2ZM208.58 75.51L208.26 72.53C210.23 72.32 212.16 72.06 214 71.77L214.47 74.73C212.58 75.03 210.59 75.3 208.58 75.51ZM218.35 74.03L217.75 71.09C219.66 70.7 221.55 70.25 223.35 69.75L224.15 72.64C222.28 73.16 220.33 73.62 218.34 74.02L218.35 74.03ZM227.93 71.5L226.98 68.65C228.82 68.04 230.57 67.38 232.35 66.62L233.52 69.38C231.67 70.17 229.83 70.86 227.92 71.5H227.93ZM237.12 67.75L235.82 65.05C237.42 64.28 239.1 63.4 240.95 62.38L242.41 65C240.51 66.05 238.78 66.95 237.13 67.75H237.12ZM245.79 63.07L244.29 60.47L246.01 59.47C247.13 58.82 248.24 58.17 249.38 57.52L250.87 60.13C249.75 60.77 248.64 61.42 247.53 62.06L245.8 63.07H245.79ZM254.21 58.27L252.79 55.63C254.66 54.63 256.41 53.76 258.13 52.97L259.38 55.7C257.72 56.46 256.03 57.3 254.21 58.27ZM262.87 54.2L261.76 51.41C263.6 50.68 265.49 50.01 267.4 49.42L268.29 52.29C266.46 52.86 264.63 53.5 262.87 54.2ZM271.94 51.25L271.19 48.35C273.06 47.87 275.02 47.43 277.01 47.05L277.57 50C275.64 50.37 273.75 50.79 271.94 51.25ZM281.33 49.36L280.88 46.39C282.76 46.11 284.75 45.86 286.77 45.65L287.07 48.63C285.09 48.83 283.16 49.07 281.32 49.35L281.33 49.36ZM290.9 48.31L290.68 45.32C292.59 45.18 294.58 45.07 296.6 45L296.71 48C294.73 48.07 292.77 48.18 290.9 48.31Z"
          fill="#565D62"
        />
        <path
          d="M371.41 73.1599C369.84 71.9899 368.18 70.8199 366.49 69.6699L368.17 67.1899C369.9 68.3599 371.59 69.5599 373.2 70.7599L371.41 73.1699V73.1599ZM363.1 67.4399C361.42 66.3599 359.68 65.2999 357.95 64.2899L359.46 61.6999C361.23 62.7299 363 63.8099 364.72 64.9099L363.1 67.4399ZM354.42 62.2899C352.65 61.3199 350.85 60.3899 349.07 59.5099L350.4 56.8199C352.22 57.7199 354.06 58.6699 355.87 59.6599L354.43 62.2899H354.42ZM345.41 57.7699C343.57 56.9299 341.71 56.1299 339.87 55.3999L340.99 52.6099C342.87 53.3599 344.78 54.1799 346.66 55.0399L345.41 57.7699ZM336.09 53.9499C334.19 53.2599 332.27 52.6199 330.38 52.0499L331.25 49.1799C333.19 49.7699 335.16 50.4199 337.11 51.1299L336.09 53.9499ZM326.5 50.9499C324.54 50.4399 322.57 49.9799 320.65 49.5999L321.24 46.6599C323.22 47.0599 325.25 47.5199 327.27 48.0499L326.51 50.9499H326.5ZM316.68 48.8999C314.69 48.5899 312.69 48.3499 310.73 48.1799L310.99 45.1899C313.01 45.3599 315.08 45.6099 317.14 45.9299L316.68 48.8899V48.8999Z"
          fill="#565D62"
        />
        <path
          d="M376.89 77.4999C376.89 77.4999 376.08 76.7799 374.6 75.6099L376.47 73.2599C378.01 74.4899 378.84 75.2199 378.88 75.2499L376.9 77.4999H376.89ZM191.88 76.3199C190.86 76.3199 189.85 76.3199 188.87 76.2999L188.91 73.2999C190.84 73.3299 192.87 73.3299 194.8 73.2999L194.84 76.2999C193.87 76.3099 192.88 76.3199 191.88 76.3199ZM1.76 73.3399L0 70.9099C0 70.9099 0.91 70.2399 2.61 69.1199L4.27 71.6199C2.66 72.6899 1.78 73.3299 1.76 73.3399ZM306.71 47.9399C304.73 47.8699 302.71 47.8799 300.8 47.9099L300.76 44.9099C302.73 44.8799 304.79 44.8699 306.82 44.9499L306.71 47.9499V47.9399ZM75.13 46.0399L75.02 43.0399C77.09 42.9699 79.19 42.9799 81.19 43.0099L81.14 46.0099C79.19 45.9799 77.15 45.9699 75.13 46.0399Z"
          fill="#565D62"
        />

        {value1 !== "" && (
          <text
            x="78"
            y="83"
            fontWeight="600"
            letterSpacing="0.6"
            textAnchor="middle"
            fill="#565D62"
            style={{
              fontFamily: "Titillium Web",
              fontSize: "25px",
              fill: "#565D62",
            }}
          >
            {value1}
          </text>
        )}
        {value2 !== "" && (
          <text
            x="188"
            y="56"
            fontWeight="600"
            letterSpacing="0.6"
            textAnchor="middle"
            fill="#565D62"
            style={{
              fontFamily: "Titillium Web",
              fontSize: "25px",
              fill: "#565D62",
            }}
          >
            {value2}
          </text>
        )}
        {value3 !== "" && (
          <text
            x="303"
            y="83"
            fontWeight="600"
            letterSpacing="0.6"
            textAnchor="middle"
            fill="#565D62"
            style={{
              fontFamily: "Titillium Web",
              fontSize: "25px",
              fill: "#565D62",
            }}
          >
            {value3}
          </text>
        )}
      </svg>
    );
  } else if (type === "klachtenloketten") {
    return (
      <svg
        width="379"
        height="128"
        viewBox="0 0 379 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="infographic"
      >
        <path
          d="M124.931 90.9733H130.982V83.9729H137.178V79.1162H130.982V72.1987H124.931V79.1162H118.736V83.9729H124.931V90.9733Z"
          fill="#E87748"
        />
        <path
          d="M257.571 75.3157H239.129V80.1724H257.571V75.3157ZM257.571 82.9995H239.129V87.8562H257.571V82.9995Z"
          fill="#E87748"
        />
        <path
          d="M204.369 121.488C226.108 112.427 236.431 87.3486 227.426 65.4735C218.422 43.5984 193.5 33.2104 171.761 42.2714C150.023 51.3324 139.699 76.411 148.704 98.2861C157.708 120.161 182.63 130.549 204.369 121.488Z"
          stroke="#E87647"
          strokeWidth="4.2"
          fill="#fff"
        />
        <path
          d="M322.653 119.487C343.294 110.883 353.096 87.0706 344.546 66.2998C335.996 45.529 312.332 35.6655 291.691 44.2691C271.05 52.8726 261.248 76.6852 269.798 97.456C278.347 118.227 302.011 128.09 322.653 119.487Z"
          stroke="#E87647"
          strokeWidth="8.51"
          fill="#fff"
        />
        <path
          d="M84.0659 121.185C105.804 112.124 116.128 87.0458 107.123 65.1707C98.1187 43.2956 73.1965 32.9077 51.4579 41.9687C29.7193 51.0296 19.3962 76.1083 28.4006 97.9834C37.4051 119.859 62.3272 130.246 84.0659 121.185Z"
          stroke="#E87647"
          strokeWidth="4.2"
          fill="#fff"
        />
        <g transform="translate(0,-2.5)">
          <path
            d="M29.8743 3.95569V2.72339H36.0488V3.95569H33.6613V11.2356H32.2823V3.95569H29.8743Z"
            fill="white"
          />
          <path
            d="M37.3042 11.2356V2.72339H42.6349V3.92463H38.6729V6.33746H41.8939V7.52835H38.6729V10.0137H42.6349V11.2356H37.3042Z"
            fill="white"
          />
          <path
            d="M48.9846 11.2356H44.2405V2.72339H45.6092V10.0033H48.9846V11.2356Z"
            fill="white"
          />
          <path
            d="M50.2297 11.2356V2.72339H55.5604V3.92463H51.5984V6.33746H54.8195V7.52835H51.5984V10.0137H55.5604V11.2356H50.2297Z"
            fill="white"
          />
          <path
            d="M57.176 11.2356V2.72339H62.4244V3.92463H58.5447V6.79311H61.7658V7.99434H58.5447V11.2253H57.176V11.2356Z"
            fill="white"
          />
          <path
            d="M69.5251 10.3348C69.0209 11.0286 68.1256 11.3807 66.8495 11.3807C65.5734 11.3807 64.6781 11.0286 64.1739 10.3348C63.6593 9.63062 63.4124 8.53293 63.4124 7.03138C63.4124 5.52983 63.6696 4.42179 64.1842 3.68655C64.6987 2.95131 65.594 2.58887 66.8598 2.58887C68.1256 2.58887 69.0209 2.95131 69.5251 3.6762C70.0397 4.40108 70.2867 5.51948 70.2867 7.02103C70.2867 8.52258 70.0294 9.63062 69.5251 10.3244V10.3348ZM65.2441 9.44422C65.522 9.93093 66.0571 10.1795 66.8495 10.1795C67.6419 10.1795 68.177 9.94128 68.4446 9.45457C68.7225 8.97822 68.8562 8.17049 68.8562 7.04174C68.8562 5.91299 68.7122 5.08455 68.4343 4.57713C68.1462 4.05935 67.6213 3.81082 66.8495 3.81082C66.0777 3.81082 65.5426 4.06971 65.2544 4.57713C64.9663 5.08455 64.8222 5.91299 64.8222 7.03138C64.8222 8.14978 64.9663 8.95751 65.2441 9.44422Z"
            fill="white"
          />
          <path
            d="M72.0051 11.2356V2.72339H74.4029L76.9756 10.0137H77.1609V2.72339H78.5296V11.2356H76.1832L73.5488 3.92463H73.3738V11.2356H72.0051Z"
            fill="white"
          />
          <path
            d="M80.5981 11.2356V2.72339H81.9668V11.2356H80.5981Z"
            fill="white"
          />
          <path
            d="M86.5153 3.79011C85.4656 3.79011 84.951 4.16291 84.951 4.89815C84.951 5.2813 85.0745 5.55054 85.3215 5.71623C85.5685 5.88192 86.1139 6.07867 86.9578 6.31685C87.8016 6.55503 88.3985 6.83463 88.7381 7.166C89.088 7.49738 89.2526 8.0048 89.2526 8.70898C89.2526 9.59955 88.9954 10.2727 88.4911 10.7179C87.9869 11.1632 87.2974 11.3807 86.4226 11.3807C85.7229 11.3807 84.951 11.2979 84.0969 11.1322L83.6544 11.0493L83.7882 9.94128C84.8996 10.0863 85.7434 10.1691 86.33 10.1691C87.3488 10.1691 87.8634 9.71346 87.8634 8.80218C87.8634 8.45009 87.7502 8.18084 87.5135 8.01516C87.2871 7.84947 86.7622 7.66307 85.9595 7.45596C85.1569 7.24885 84.5497 6.96925 84.1586 6.61716C83.7676 6.26507 83.5618 5.69552 83.5618 4.92921C83.5618 4.16291 83.8088 3.57264 84.313 3.17913C84.807 2.78562 85.4965 2.58887 86.3815 2.58887C87.0195 2.58887 87.7605 2.66136 88.6249 2.81669L89.0571 2.88918L88.9439 4.00757C87.7913 3.87295 86.9783 3.81082 86.4947 3.81082L86.5153 3.79011Z"
            fill="white"
          />
          <path
            d="M93.6882 11.3703C92.4532 11.3703 91.6197 11.0286 91.1772 10.3347C90.7347 9.65128 90.5186 8.51218 90.5186 6.93814C90.5186 5.3641 90.745 4.24571 91.1875 3.5726C91.63 2.90985 92.4635 2.56812 93.6984 2.56812C94.4291 2.56812 95.2421 2.67167 96.1168 2.87878L96.0653 3.99718C95.3244 3.86255 94.5835 3.80042 93.8425 3.80042C93.1016 3.80042 92.5973 4.01789 92.34 4.46317C92.0725 4.90846 91.949 5.7369 91.949 6.95885C91.949 8.1808 92.0725 9.00924 92.3298 9.45453C92.587 9.89982 93.081 10.1173 93.8219 10.1173C94.5629 10.1173 95.3141 10.0552 96.0653 9.93088L96.1065 11.07C95.2627 11.2564 94.46 11.3392 93.6984 11.3392L93.6882 11.3703Z"
            fill="white"
          />
          <path
            d="M102.713 11.2356V7.55941H99.0601V11.2356H97.6914V2.72339H99.0601V6.34782H102.713V2.72339H104.092V11.2356H102.713Z"
            fill="white"
          />
        </g>
        <path
          d="M163.615 8.64675H160.692V0.134521H163.615C164.911 0.134521 165.807 0.465898 166.28 1.12865C166.753 1.7914 167 2.82695 167 4.24566C167 4.97055 166.949 5.58152 166.856 6.07859C166.764 6.57565 166.599 7.03129 166.352 7.43516C165.879 8.24289 164.973 8.6364 163.615 8.6364V8.64675ZM165.58 4.24566C165.58 3.22046 165.457 2.47487 165.2 2.01922C164.942 1.56358 164.417 1.33576 163.615 1.33576H162.061V7.4248H163.615C164.428 7.4248 164.973 7.12449 165.241 6.51352C165.374 6.20285 165.457 5.88183 165.508 5.52974C165.56 5.17766 165.58 4.75308 165.58 4.2353V4.24566Z"
          fill="white"
        />
        <path
          d="M168.729 8.64675V0.134521H170.098V8.64675H168.729Z"
          fill="white"
        />
        <path
          d="M175.604 5.39512V4.17317H178.012V8.50177C177.785 8.56391 177.343 8.62604 176.664 8.68817C175.985 8.75031 175.491 8.79173 175.192 8.79173C173.906 8.79173 173.021 8.43964 172.537 7.72511C172.053 7.01058 171.806 5.89218 171.806 4.38028C171.806 2.86837 172.053 1.74998 172.558 1.05616C173.052 0.362341 173.916 0.0102539 175.141 0.0102539C175.861 0.0102539 176.684 0.093098 177.59 0.258786L178.022 0.34163L177.971 1.43931C176.972 1.30469 176.077 1.24256 175.295 1.24256C174.513 1.24256 173.967 1.47038 173.669 1.91567C173.381 2.36096 173.227 3.1894 173.227 4.40099C173.227 5.61259 173.36 6.44103 173.638 6.90702C173.916 7.36267 174.472 7.60084 175.316 7.60084C175.912 7.60084 176.365 7.55942 176.664 7.48693V5.41583H175.614L175.604 5.39512Z"
          fill="white"
        />
        <path
          d="M179.854 8.64675V0.134521H181.222V8.64675H179.854Z"
          fill="white"
        />
        <path
          d="M182.468 1.36683V0.134521H188.642V1.36683H186.255V8.64675H184.876V1.36683H182.468Z"
          fill="white"
        />
        <path
          d="M188.683 8.64675L190.772 0.134521H193.499L195.599 8.64675H194.22L193.767 6.82418H190.515L190.062 8.64675H188.694H188.683ZM191.832 1.29434L190.783 5.60223H193.489L192.45 1.29434H191.832Z"
          fill="white"
        />
        <path
          d="M196.206 8.64675L198.295 0.134521H201.022L203.122 8.64675H201.743L201.29 6.82418H198.038L197.585 8.64675H196.216H196.206ZM199.355 1.29434L198.305 5.60223H201.012L199.973 1.29434H199.355Z"
          fill="white"
        />
        <path
          d="M209.183 8.64675H204.439V0.134521H205.807V7.41445H209.183V8.64675Z"
          fill="white"
        />
        <path
          d="M263.57 1.36683V0.134521H269.745V1.36683H267.357V8.64675H265.978V1.36683H263.57Z"
          fill="white"
        />
        <path
          d="M276.609 7.74592C276.105 8.43974 275.209 8.79183 273.933 8.79183C272.657 8.79183 271.762 8.43974 271.258 7.74592C270.743 7.04175 270.496 5.94406 270.496 4.44252C270.496 2.94097 270.753 1.83293 271.268 1.09768C271.782 0.362443 272.678 0 273.944 0C275.209 0 276.105 0.362443 276.609 1.08733C277.123 1.81221 277.37 2.93061 277.37 4.43216C277.37 5.93371 277.113 7.04175 276.609 7.73557V7.74592ZM272.328 6.85535C272.606 7.34206 273.141 7.59059 273.933 7.59059C274.726 7.59059 275.261 7.35241 275.528 6.86571C275.806 6.38935 275.94 5.58162 275.94 4.45287C275.94 3.32412 275.796 2.49568 275.518 1.98826C275.23 1.47048 274.705 1.22195 273.933 1.22195C273.161 1.22195 272.626 1.48084 272.338 1.98826C272.05 2.49568 271.906 3.32412 271.906 4.44252C271.906 5.56091 272.05 6.36864 272.328 6.85535Z"
          fill="white"
        />
        <path
          d="M278.122 1.36683V0.134521H284.296V1.36683H281.909V8.64675H280.53V1.36683H278.122Z"
          fill="white"
        />
        <path
          d="M284.337 8.64675L286.426 0.134521H289.154L291.253 8.64675H289.874L289.421 6.82418H286.169L285.716 8.64675H284.348H284.337ZM287.486 1.29434L286.437 5.60223H289.143L288.104 1.29434H287.486Z"
          fill="white"
        />
        <path
          d="M291.85 8.64675L293.939 0.134521H296.666L298.765 8.64675H297.386L296.933 6.82418H293.681L293.229 8.64675H291.86H291.85ZM294.999 1.29434L293.949 5.60223H296.655L295.616 1.29434H294.999Z"
          fill="white"
        />
        <path
          d="M304.827 8.64675H300.083V0.134521H301.451V7.41445H304.827V8.64675Z"
          fill="white"
        />
        <path
          d="M308.058 8.64675L310.147 0.134521H312.874L314.973 8.64675H313.594L313.142 6.82418H309.89L309.437 8.64675H308.068H308.058ZM311.207 1.29434L310.157 5.60223H312.864L311.824 1.29434H311.207Z"
          fill="white"
        />
        <path
          d="M315.57 8.64675L317.659 0.134521H320.386L322.486 8.64675H321.107L320.654 6.82418H317.402L316.949 8.64675H315.581H315.57ZM318.719 1.29434L317.67 5.60223H320.376L319.337 1.29434H318.719Z"
          fill="white"
        />
        <path
          d="M323.803 8.64675V0.134521H326.201L328.773 7.4248H328.959V0.134521H330.327V8.64675H327.981L325.347 1.33576H325.172V8.64675H323.803Z"
          fill="white"
        />
        <path
          d="M331.583 1.36683V0.134521H337.757V1.36683H335.37V8.64675H333.991V1.36683H331.583Z"
          fill="white"
        />
        <path
          d="M337.799 8.64675L339.888 0.134521H342.615L344.714 8.64675H343.335L342.882 6.82418H339.63L339.178 8.64675H337.809H337.799ZM340.948 1.29434L339.898 5.60223H342.604L341.565 1.29434H340.948Z"
          fill="white"
        />
        <path
          d="M350.775 8.64675H346.031V0.134521H347.4V7.41445H350.775V8.64675Z"
          fill="white"
        />
        <path
          d="M274.767 23.5589V15.0466H277.154L279.048 21.6949L280.941 15.0466H283.339V23.5589H281.96V16.5792H281.775L279.779 23.2171H278.348L276.352 16.5792H276.166V23.5589H274.798H274.767Z"
          fill="white"
        />
        <path
          d="M285.387 23.5589V15.0466H290.718V16.2479H286.756V18.6607H289.977V19.8516H286.756V22.3369H290.718V23.5589H285.387Z"
          fill="white"
        />
        <path
          d="M297.067 23.5589H292.323V15.0466H293.692V22.3266H297.067V23.5589Z"
          fill="white"
        />
        <path
          d="M301.245 23.5589H298.323V15.0466H301.245C302.542 15.0466 303.437 15.378 303.911 16.0408C304.384 16.7035 304.631 17.7391 304.631 19.1578C304.631 19.8827 304.58 20.4936 304.487 20.9907C304.394 21.4878 304.23 21.9434 303.983 22.3473C303.509 23.155 302.604 23.5485 301.245 23.5485V23.5589ZM303.211 19.1578C303.211 18.1326 303.087 17.387 302.83 16.9313C302.573 16.4757 302.048 16.2479 301.245 16.2479H299.691V22.3369H301.245C302.058 22.3369 302.604 22.0366 302.871 21.4256C303.005 21.115 303.087 20.7939 303.139 20.4419C303.19 20.0898 303.211 19.6652 303.211 19.1474V19.1578Z"
          fill="white"
        />
        <path
          d="M306.36 23.5589V15.0466H307.729V23.5589H306.36Z"
          fill="white"
        />
        <path
          d="M309.787 23.5589V15.0466H312.185L314.757 22.3369H314.943V15.0466H316.311V23.5589H313.965L311.33 16.2479H311.156V23.5589H309.787Z"
          fill="white"
        />
        <path
          d="M321.827 20.3072V19.0853H324.235V23.4139C324.009 23.476 323.566 23.5382 322.887 23.6003C322.208 23.6624 321.714 23.7038 321.415 23.7038C320.129 23.7038 319.244 23.3518 318.76 22.6372C318.277 21.9227 318.03 20.8043 318.03 19.2924C318.03 17.7805 318.277 16.6621 318.781 15.9683C319.275 15.2745 320.139 14.9224 321.364 14.9224C322.084 14.9224 322.908 15.0052 323.813 15.1709L324.245 15.2537L324.194 16.3514C323.196 16.2168 322.3 16.1547 321.518 16.1547C320.736 16.1547 320.191 16.3825 319.892 16.8278C319.604 17.2731 319.45 18.1015 319.45 19.3131C319.45 20.5247 319.584 21.3531 319.862 21.8191C320.139 22.2748 320.695 22.513 321.539 22.513C322.136 22.513 322.589 22.4715 322.887 22.399V20.3279H321.837L321.827 20.3072Z"
          fill="white"
        />
        <path
          d="M326.077 23.5589V15.0466H331.408V16.2479H327.446V18.6607H330.667V19.8516H327.446V22.3369H331.408V23.5589H326.077Z"
          fill="white"
        />
        <path
          d="M333.034 23.5589V15.0466H335.432L338.004 22.3369H338.19V15.0466H339.558V23.5589H337.212L334.578 16.2479H334.403V23.5589H333.034Z"
          fill="white"
        />
        {value1 !== "" && (
          <text
            x="66"
            y="89"
            fontWeight="600"
            letterSpacing="0.6"
            textAnchor="middle"
            fill="#565D62"
            style={{
              fontFamily: "Titillium Web",
              fontSize: "20px",
              fill: "#565D62",
            }}
          >
            {value1}
          </text>
        )}
        {value2 !== "" && (
          <text
            x="188"
            y="89"
            fontWeight="600"
            letterSpacing="0.6"
            textAnchor="middle"
            fill="#565D62"
            style={{
              fontFamily: "Titillium Web",
              fontSize: "20px",
              fill: "#565D62",
            }}
          >
            {value2}
          </text>
        )}
        {value3 !== "" && (
          <text
            x="308"
            y="89"
            fontWeight="600"
            letterSpacing="0.6"
            textAnchor="middle"
            fill="#565D62"
            style={{
              fontFamily: "Titillium Web",
              fontSize: "20px",
              fill: "#565D62",
            }}
          >
            {value3}
          </text>
        )}
      </svg>
    );
  }
}
export default FrontpageInfographic;

import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSearchViewVisible } from "../redux/actions";

import SearchField from "./searchfield";
import { motion } from "framer-motion";
import ArticleListSmall from "./articleListSmall";

const SearchResults = ({ emptySearchResults = () => {} }) => {
  const dispatch = useDispatch();
  const searchResults = useSelector((state) => {
    return state.searchResults;
  });
  const isSearching = useSelector((state) => {
    return state.searchStatus;
  });
  const searchViewVisible = useSelector((state) => {
    return state.searchViewVisible;
  });
  const { categories } = useSelector((state) => {
    return state.categories;
  });

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;
    if (key === "Escape") {
      dispatch(setSearchViewVisible(false));
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, []);

  if (!searchViewVisible) {
    document.body.style.overflow = "unset";
    return false;
  } else {
    document.body.style.overflow = "hidden";
  }

  return (
    <motion.div
      className="searchResults"
      variants={{
        close: {
          opacity: 0,
        },
        open: {
          opacity: 1,
          transition: {
            when: "beforeChildren",
          },
        },
      }}
      animate="open"
      initial="close"
      exit="close"
      key="searchResults"
    >
      <div
        className="background"
        onClick={() => {
          dispatch(setSearchViewVisible(false));
        }}
      ></div>
      <motion.div
        variants={{
          close: {
            scale: 2,
            opacity: 0,
          },
          open: {
            scale: 1,
            opacity: 1,
            transition: {
              // type: "spring",
              // stiffness: 100,
              when: "beforeChildren",
            },
          },
        }}
      >
        <h2 className="searchResultsTitle">Doorzoek de jaarverslagen</h2>
        <SearchField setFocus={true} />

        {isSearching && (
          <img className="loadingIcon" src="./img/loading.svg" alt="" />
        )}
        {!isSearching &&
          searchResults.tegels.length === 0 &&
          searchResults.stories.length === 0 && (
            <div className="resultsContainer noResults">
              Helaas hebben wij niks gevonden dat aan uw zoekterm voldeed.
            </div>
          )}

        {!isSearching &&
          (searchResults.tegels.length > 0 ||
            searchResults.stories.length > 0) && (
            <motion.div
              variants={{
                initial: {},
                enter: {},
              }}
              animate="enter"
              initial="initial"
              transition={{ staggerChildren: 0.02 }}
              className="resultsContainer"
            >
              <motion.h4
                className="underline"
                variants={{
                  enter: {
                    opacity: 1,
                    y: 0,
                  },
                  initial: {
                    opacity: 0,
                    y: 200,
                  },
                }}
              >
                <span>resultaten</span>
              </motion.h4>

              <div className="results articleLinks">
                <ArticleListSmall articles={searchResults} />
                {/* {searchResults.map((searchResult, index) => {
                if (undefined !== categories[searchResult]) {
                  return (
                    <motion.div
                      key={`tegel-${searchResult}`}
                      variants={{
                        enter: {
                          opacity: 1,
                          x: 0,
                        },
                        initial: {
                          opacity: 0,
                          x: 100,
                        },
                      }}
                    >
                      <ThumbnailTegel
                        article={categories[searchResult]}
                        displaySize="small"
                      />
                    </motion.div>
                  );
                } else {
                  return <div key={index}>{searchResult}</div>;
                }
              })} */}
              </div>
            </motion.div>
          )}
      </motion.div>
    </motion.div>
  );
};
export default SearchResults;

import React, { useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { fetchIntroduction } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import ThumbnailStory from "./thumbnailStory";
import ThumbnailTegel from "./thumbnailTegel";

function ExtraIntroduction({ history }) {
  const match = useRouteMatch();
  let terms = [];
  if (match.params.terms) {
    terms = match.params.terms.split("/");
    if (terms.length && terms[terms.length - 1] == "") {
      terms.splice(-1, 1); // laatste is leeg ivm trailing slash
    }
  }
  const { categories, loading: categoriesAreLoading } = useSelector((state) => {
    return state.categories;
  });

  const { introduction, loading: introductionIsLoading } = useSelector(
    (state) => {
      return state.introduction;
    }
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchIntroduction(terms[terms.length - 1]));
  }, []);

  return (
    <section className="introduction">
      <div className="wrapper">
        {introductionIsLoading && <h1>"loading..."</h1>}
        {!introductionIsLoading && (
          <>
            <h1>{introduction.title}</h1>
            <p
              dangerouslySetInnerHTML={{ __html: introduction.introduction }}
            />
            <div className="links">
              {introduction.links.map((link, linkIndex) => {
                switch (link.type) {
                  case "verhaal":
                    return <ThumbnailStory link={link} />;
                  case "tegel":
                    return (
                      <>
                        {!categoriesAreLoading &&
                          categories[link.titleSave] && (
                            <ThumbnailTegel
                              displaySize="small"
                              article={categories[link.titleSave]}
                            />
                          )}
                      </>
                    );
                  default:
                    return false;
                }
              })}
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default ExtraIntroduction;

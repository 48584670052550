import React from "react";
import { Link } from "react-router-dom";
import SearchField from "../components/searchfield";
import { motion } from "framer-motion";
const variants = {
  wrapper: {
    initial: {},
    animate: {
      transition: {
        staggerChildren: 0.25,
        delayChildren: 0.5,
      },
    },
  },
  content: {
    initial: {
      opacity: 0,
      // scale: 1.2,
    },
    animate: {
      opacity: 1,
      // scale: 1,
      transition: { duration: 1 },
    },
  },
  list: {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.1,
        staggerChildren: 0.1,
        delayChildren: 1.2,
      },
    },
  },
  listitem: {
    initial: { opacity: 0, scale: 1.1, originX: 0 },
    animate: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.2 },
    },
  },
};

function Shortcuts({ homepageDataIsLoading, homepageData, sectionInView }) {
  return (
    <motion.div
      variants={variants.wrapper}
      initial="initial"
      animate={sectionInView ? "animate" : false}
    >
      {!homepageDataIsLoading && (
        <>
          <motion.h2
            variants={variants.content}
            dangerouslySetInnerHTML={{
              __html: homepageData.jaarverslagen_titel,
            }}
          />

          <motion.p
            variants={variants.content}
            dangerouslySetInnerHTML={{
              __html: homepageData.jaarverslagen_introductie,
            }}
          />
        </>
      )}

      <div className="shortcuts">
        <motion.i variants={variants.content}>- Direct naar -</motion.i>
        <motion.ul variants={variants.list}>
          <motion.li variants={variants.listitem}>
            <Link
              className="jaarverslag-consumenten"
              to="/inhoud/jaarverslag-consumenten"
            >
              <span>
                <span>Jaarverslag Consumenten</span>
              </span>
            </Link>
          </motion.li>
          <motion.li variants={variants.listitem}>
            <Link
              className="vastlegging-geschillencommissies-zorg"
              to="/inhoud/jaarverslag-consumenten/zorg-welzijn"
            >
              <span>Verslaglegging Geschillencommissies Zorg</span>
            </Link>
          </motion.li>
          <motion.li variants={variants.listitem}>
            <Link
              className="jaarverslag-beroep-en-bedrijf"
              to="/inhoud/jaarverslag-beroep-en-bedrijf"
            >
              <span>Jaarverslag Beroep & Bedrijf</span>
            </Link>
          </motion.li>
          <motion.li variants={variants.listitem}>
            <Link
              className="jaarverslag-klachtenloket-kinderopvang"
              to="/inhoud/jaarverslag-klachtenloket-kinderopvang"
            >
              <span>Jaarverslag Klachtenloket Kinderopvang</span>
            </Link>
          </motion.li>
          <motion.li variants={variants.listitem}>
            <Link
              className="jaarverslag-klachtenloket-zorg"
              to="/inhoud/jaarverslag-klachtenloket-zorg"
            >
              <span>Jaarverslag Klachtenloket Zorg</span>
            </Link>
          </motion.li>
        </motion.ul>
      </div>
      <i>- Of zoek in de jaarverslagen -</i>
      <SearchField fakeMobileButton={true} />
    </motion.div>
  );
}

export default Shortcuts;

import React from "react";
import { useSelector } from "react-redux";

import ThumbnailStory from "./thumbnailStory";
import ThumbnailTegel from "./thumbnailTegel";
function ArticleListSmall({ articles }) {
  const { categories, loading: categoriesAreLoading } = useSelector((state) => {
    return state.categories;
  });

  if (categoriesAreLoading) {
    return null;
  }

  return (
    <div
      className={`articleLinks relatedArticles ${
        articles.tegels.length && articles.stories.length
          ? "multiple-types"
          : ""
      } ${
        articles.stories.length && articles.stories.length >= 2
          ? "largeStories"
          : "smallStories"
      }`}
    >
      <div className="links">
        {articles.tegels.length > 0 && (
          <div className="tegels">
            <h4>commissies</h4>

            <div className="items">
              {articles.tegels.map((relatedArticle, index) => {
                if (categories[relatedArticle.titleSave]) {
                  return (
                    <ThumbnailTegel
                      key={`relatedArticle-${index}`}
                      displaySize="small"
                      article={categories[relatedArticle.titleSave]}
                      variants={{
                        enter: {
                          opacity: 1,
                          x: 0,
                        },
                        initial: {
                          opacity: 0,
                          x: 100,
                        },
                      }}
                    />
                  );
                }
              })}
            </div>
          </div>
        )}
        {articles.stories.length > 0 && (
          <div className="stories">
            <h4>achtergronden</h4>

            <div className="items">
              {articles.stories.map((relatedArticle, index) => (
                <ThumbnailStory story={relatedArticle} size="small" />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ArticleListSmall;

import React, { useLayoutEffect, useState, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { useWindowSize } from "../hooks/useWindowSize";

const initialOffsetSection = 150;

const HomepageSection = ({ children, cls, showOnLoad = false }) => {
  const { ref, inView, entry } = useInView({});

  return (
    <>
      <motion.section
        className={cls}
        ref={ref}
        initial={{
          scale: 1,
          originY: 0,
          y: initialOffsetSection,
          opacity: 0,
        }}
        animate={inView || showOnLoad ? { scale: 1, y: 0, opacity: 1 } : false}
        exit={{ x: -100, opacity: 0 }}
        transition={{
          duration: 0.6,
          ease: [0.43, 0.13, 0.23, 0.96],
        }}
      >
        {children !== undefined
          ? React.cloneElement(children, {
              sectionInView: inView,
            })
          : null}
      </motion.section>
    </>
  );
};

export default HomepageSection;

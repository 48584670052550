import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchStory } from "../redux/actions";
import ArticleHeader from "./svgComponents/articleHeaderStory";
import { motion } from "framer-motion";
import Sidebar from "./sidebar";
import ArticleContent from "./articleContent";
import StoryOverview from "./storyOverview";
import qs from "qs";
import ShareArticle from "./shareArticle";

const Verhaal = () => {
  const match = useRouteMatch();
  let terms = [];
  const [storyOpenStatus, setStoryOpenStatus] = useState(false);

  if (match.params.terms) {
    terms = match.params.terms.split("/"); //substr haalt eerste slash weg
    if (terms.length && terms[terms.length - 1] == "") {
      terms.splice(-1, 1); // laatste is leeg ivm trailing slash
    }
  }
  let storySlug = terms[terms.length - 1];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStory(storySlug));
    setStoryOpenStatus(true);
  }, [storySlug]);

  const { story, loading: storyIsLoading } = useSelector((state) => {
    return state.story;
  });

  return (
    <>
      <div className="storyImageBackground" />
      <motion.div className="wrapper withSidebar" exit={{ opacity: 0 }}>
        <article
          className={`story ${storyOpenStatus ? "open" : "closed"} ${
            storyIsLoading ? "loading" : ""
          }`}
        >
          <div className="header">
            <ArticleHeader story={story} storyIsLoading={storyIsLoading} />

            <div className="heading">
              {!storyIsLoading && (
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 0.2 }}
                  transition={{ delay: 0.5 }}
                  className="headerBackgroundImage"
                  src={story.header_settings.afbeelding_centraal}
                  alt=""
                />
              )}
              <h1
                onClick={() => {
                  setStoryOpenStatus(!storyOpenStatus);
                }}
              >
                {story.title}
              </h1>
              {!storyIsLoading && (
                <motion.h2
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  dangerouslySetInnerHTML={{ __html: story.subtitel }}
                />
              )}
            </div>
          </div>

          {!storyIsLoading && (
            <motion.div className={`articleContent`}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="introduction"
                dangerouslySetInnerHTML={{ __html: story.intro }}
              />
              {story.tekst_layout && (
                <ArticleContent content={story.tekst_layout} />
              )}
            </motion.div>
          )}
          <ShareArticle slug={storySlug} />
        </article>
        {!storyIsLoading && (
          <StoryOverview hideArticleID={story.id} thumbnailSize="small" />
        )}
      </motion.div>
    </>
  );
};

export default Verhaal;

import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSearchViewVisible } from "../redux/actions";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import SearchField from "./searchfield";
import Breadcrumbs from "./breadcrumbs";
import SVG from "react-inlinesvg";

const Sidebar = ({ history }) => {
  const dispatch = useDispatch();

  /* LOAD GLOBAL STATES */
  const { stories, storiesAreLoading } = useSelector((state) => {
    return state.stories;
  });
  const { categories, loading: categoriesAreLoading } = useSelector((state) => {
    return state.categories;
  });

  const [mobileMenuOpen, setMobileMenuOpenStatus] = useState(false);

  /* background size */
  const elementRef = useRef();
  const [backgroundSize, setBackgroundSize] = useState(getSize);

  function getSize() {
    const isClient = typeof window === "object";

    return isClient && elementRef.current
      ? elementRef.current.clientWidth
      : undefined;
  }
  function handleResize() {
    setBackgroundSize(getSize());
  }
  useEffect(() => {
    setBackgroundSize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [history.location.pathname]);

  let jaarverslag = null;
  let breadcrumbTerms = [];
  let viewMode = null;
  if (history.location.pathname) {
    breadcrumbTerms = history.location.pathname.split("/"); //substr haalt eerste slash weg
    if (
      breadcrumbTerms.length &&
      breadcrumbTerms[breadcrumbTerms.length - 1] === ""
    ) {
      breadcrumbTerms.splice(-1, 1); // laatste is leeg ivm trailing slash
    }
    if (breadcrumbTerms.length) {
      if (breadcrumbTerms[0] === "") {
        breadcrumbTerms.splice(0, 1); // eerst is leeg ivm prefix slash
      }
      switch (breadcrumbTerms[0]) {
        case "inhoud":
          viewMode = "cijferIndex";
          if (breadcrumbTerms.length > 0) {
            jaarverslag = breadcrumbTerms[1];
          } else {
            return false;
          }
          break;
        case "achtergrond":
          viewMode = "achtergrond";
          break;
        default:
          return <></>;
      }
      breadcrumbTerms.splice(0, 1); // remove "inhoud/achtergrond" from the array
    }
  }
  let currentlyActiveTerm = breadcrumbTerms[breadcrumbTerms.length - 1];
  return (
    <motion.aside
      // initial={{ x: "-100%" }}
      // animate={{ x: "0%" }}
      // exit={{ x: "-100%" }}
      ref={elementRef}
      className={`${mobileMenuOpen ? "mobileMenuOpen" : ""}`}
    >
      <motion.div
        className="background"
        // initial={{ opacity: 0, scaleX: 0 }}
        // animate={{ opacity: 0.75, scaleX: 1 }}
        // transition={{ delay: 0.5 }}
        // style={{ width: `${backgroundSize}px` }}
      ></motion.div>

      <Link to="/" className="homelogo">
        <SVG
          cacheRequests={true}
          src={`${process.env.PUBLIC_URL}/img/logo-side.svg`}
        />
      </Link>
      <div className="mobileMenuHeader">
        <Link
          className="logo"
          to="/"
          onClick={() => setMobileMenuOpenStatus(false)}
        >
          <img
            src={`${process.env.PUBLIC_URL}/img/logo-mobile-header.svg`}
            alt="logo"
            className="logo"
          />
        </Link>

        <img
          src={`${process.env.PUBLIC_URL}/img/icons/icon-index.svg`}
          alt="menu"
          onClick={() => setMobileMenuOpenStatus(!mobileMenuOpen)}
        />
      </div>
      <div className="header"></div>

      <div className={`navigation`}>
        {!categoriesAreLoading && !storiesAreLoading && (
          <Breadcrumbs onClickItem={() => setMobileMenuOpenStatus(false)} />
        )}
      </div>
      <div className="footer">
        <div className="search">
          <SearchField
            fakeMobileButton={true}
            closeMobileMenu={() => setMobileMenuOpenStatus(false)}
          />
        </div>
      </div>
      {/* <div className="footer">
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <button className="straight orange">Download dit artikel</button>
          <div className="share">
            <strong>
              <span>deel dit artikel</span>
            </strong>
            <div className="shareLinks">
              <Link to="/">
                <img
                  src="./img/icons/facebook-square-brands.svg"
                  alt="facebook"
                />
              </Link>
              <Link to="/">
                <img src="./img/icons/linkedin-brands.svg" alt="facebook" />
              </Link>
              <Link to="/">
                <img
                  src="./img/icons/twitter-square-brands.svg"
                  alt="facebook"
                />
              </Link>
              <Link to="/">
                <img src="./img/icons/envelope-square-solid.svg" alt="mail" />
              </Link>
            </div>
          </div>
        </motion.div>
      </div> */}
    </motion.aside>
  );
};

export default Sidebar;

import axios from "axios";
import * as types from "./actionTypes";

// const domain = "http://localhost/DOOR/DGC2017-backend/";

const domain =
  "https://www.samenwerkenaankwaliteit.nl/backend-jaarverslag-2023";

export function fetchHomepageData() {
  const request = axios.get(`${domain}/?r=homepage`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: types.SET_HOMEPAGE_DATA, payload: data });
    });
  };
}

export function fetchArticle(articleSlug) {
  const request = axios.get(`${domain}/?r=article&slug=${articleSlug}`);
  return (dispatch) => {
    dispatch({ type: types.UNLOAD_ARTICLE });
    request.then(({ data }) => {
      dispatch({ type: types.SET_ACTIVE_ARTICLE, payload: data });
    });
  };
}

export function fetchIntroduction(slug) {
  const request = axios.get(`${domain}/?r=introduction&slug=${slug}`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: types.FETCH_INTRODUCTION, payload: data });
    });
  };
}

export function fetchPage(pageSlug) {
  const request = axios.get(`${domain}/?r=page&slug=${pageSlug}`);
  return (dispatch) => {
    dispatch({ type: types.UNLOAD_PAGE });
    request.then(({ data }) => {
      dispatch({ type: types.SET_ACTIVE_PAGE, payload: data });
    });
  };
}
export function fetchStory(storySlug) {
  const request = axios.get(`${domain}/?r=story&slug=${storySlug}`);
  return (dispatch) => {
    dispatch({ type: types.UNLOAD_STORY });
    request.then(({ data }) => {
      dispatch({ type: types.SET_ACTIVE_STORY, payload: data });
    });
  };
}

export function fetchCategoriesAndTags() {
  const request = axios.get(`${domain}/?r=categories`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: types.FETCH_CATEGORIES, payload: data });
    });
  };
}
export function fetchFaqs() {
  const request = axios.get(`${domain}/?r=faq`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: types.FETCH_FAQ, payload: data });
    });
  };
}

export function fetchStories() {
  const request = axios.get(`${domain}/?r=stories`);
  return (dispatch) => {
    request.then(({ data }) => {
      dispatch({ type: types.FETCH_STORIES, payload: data });
    });
  };
}
export function searchLookup(searchterm) {
  const request = axios.get(`${domain}/?r=search&string=${searchterm}`);
  return (dispatch) => {
    dispatch({ type: types.IS_SEARCHING, payload: true });
    dispatch({ type: types.CURRENT_SEARCHTERM, payload: searchterm });
    dispatch({ type: types.SEARCH_VIEW_VISIBLE, payload: true });

    request.then(({ data }) => {
      dispatch({ type: types.SEARCHLOOKUP, payload: data });
      dispatch({ type: types.IS_SEARCHING, payload: false });
    });
  };
}

export function emptySearchResults() {
  return {
    type: types.EMPTYSEARCHRESULTS,
    payload: null,
  };
}

export function search(query) {
  return {
    type: types.SEARCH,
    payload: query,
  };
}

export function setSearchViewVisible(visibilityState) {
  return {
    type: types.SEARCH_VIEW_VISIBLE,
    payload: visibilityState,
  };
}

// export function setIsSearching(isSearching) {
//   return {
//     type: types.IS_SEARCHING,
//     payload: isSearching,
//   };
// }

export function unloadArticle() {
  return {
    type: types.UNLOAD_ARTICLE,
  };
}

export function setArticleFilter(filter) {
  return {
    type: types.SET_FILTER,
    payload: filter,
  };
}

export function setCurrentCategories(categories) {
  return {
    type: types.SET_CURRENT_CATEGORIES,
    payload: categories,
  };
}

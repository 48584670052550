import * as types from "../actionTypes";

const initialFAQState = {
  loading: true,
  faq: [],
};
export function faqs(state = initialFAQState, action) {
  switch (action.type) {
    case types.FETCH_FAQ:
      return {
        ...state,
        loading: false,
        faqs: action.payload.faqs,
      };
    default:
      return state;
  }
}

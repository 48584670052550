import * as types from "../actionTypes";

// export function activeCategories(state = [], action) {
//   switch (action.type) {
//     case types.SET_CURRENT_CATEGORIES:
//       return action.payload;

//     default:
//       return state;
//   }
// }

const initialCategoriesState = {
  loading: true,
  categories: [],
};
export function categories(state = initialCategoriesState, action) {
  switch (action.type) {
    case types.FETCH_CATEGORIES:
      return {
        ...state,
        loading: false,
        categories: action.payload.categories,
      };
    default:
      return state;
  }
}

// export function tags(state = [], action) {
//   switch (action.type) {
//     case types.FETCH_CATEGORIES:
//       return action.payload.tags;
//     default:
//       return state;
//   }
// }

import React from "react";
import { Link } from "react-router-dom";
import LazyLoadBackgroundImage from "./lazyloadBackgroundImage";
import { motion } from "framer-motion";
function ThumbnailStory({
  story,
  // storyThumbnailVariants = {},
  size = "large",
  delay = 0,
}) {
  return (
    <motion.div
      key={`storyThumbnail-${story.post_name}`}
      className={`storyThumbnail ${story.color} ${size}`}
      // variants={storyThumbnailVariants}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay }}
    >
      <Link to={story.link}>
        <div className="image">
          <LazyLoadBackgroundImage src={`${story.image}`} />
        </div>

        <h2>
          <span>{story.title}</span>
        </h2>
        <h3>
          <span>{story.label}</span>
          <br />
          <span className="name">{story.name}</span>
        </h3>
      </Link>
    </motion.div>
  );
}

export default ThumbnailStory;

import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import StoryOverview from "../components/storyOverview";

const variants = {
  wrapper: {
    initial: {},
    animate: {
      transition: {
        staggerChildren: 0.25,
      },
    },
  },
  content: {
    initial: {
      opacity: 0,
      scale: 1.2,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: { duration: 1 },
    },
  },
};

const StoryOverviewHome = ({ homepageData, sectionInView }) => {
  const [wasInView, setWasInView] = useState(false);
  useEffect(() => {
    if (sectionInView) {
      setWasInView(true);
    }
  }, [sectionInView]);
  return (
    <motion.div
      variants={variants.wrapper}
      initial="initial"
      animate={sectionInView ? "animate" : false}
    >
      <motion.h2
        variants={variants.content}
        dangerouslySetInnerHTML={{
          __html: homepageData.achtergronden_titel,
        }}
      />
      <StoryOverview sectionInView={wasInView}>
        <motion.p
          variants={variants.content}
          dangerouslySetInnerHTML={{
            __html: homepageData.achtergronden_introductie,
          }}
        />
      </StoryOverview>
    </motion.div>
  );
};
export default StoryOverviewHome;

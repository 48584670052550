import React, { useEffect } from "react";
import { fetchFaqs } from "../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import Accordion from "./accordion";
import { motion } from "framer-motion";
function Faq(
  { title = "", showCategories = ["brede-context"] },
  sectionInView = true
) {
  const { faqs, loading } = useSelector((state) => {
    return state.faqs;
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFaqs());
  }, []);
  if (loading) {
    return <>Loading</>;
  }
  var filteredFAQs = faqs.filter(function (e) {
    for (let i = 0; i < e.categories.length; i++) {
      if (showCategories.indexOf(e.categories[i]) > -1) {
        return true;
      }
    }
    return false;
  });
  return (
    <motion.div>
      {title.length ? (
        <motion.h2
          initial={{ opacity: 0 }}
          animate={sectionInView ? { opacity: 1 } : false}
          transition={{ duration: 1 }}
        >
          {title}
        </motion.h2>
      ) : null}
      <Accordion
        items={filteredFAQs}
        showIntroductionWhenClosed={false}
        sectionInView={sectionInView}
      />
    </motion.div>
  );
}

export default Faq;

import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";

function CijferIndexFilterContent({ setFilter, watchElementRef }) {
  const inputElement = useRef();

  const [inputValue, setInputValue] = useState("");

  const onInputChange = (searchTerm) => {
    setInputValue(searchTerm);
    setFilter(searchTerm);
  };
  const emptySearchField = () => {
    onInputChange("");
  };

  const [
    shouldBeAvailableAccordingToScroll,
    setShouldBeAvailableAccordingToScroll,
  ] = useState(false);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const { isIntersecting } = entry;
        if (isIntersecting) {
          setShouldBeAvailableAccordingToScroll(false);
        } else {
          setShouldBeAvailableAccordingToScroll(true);
          if (inputElement.current) {
            setTimeout(() => {
              inputElement.current.focus();
            }, 250);
          }
        }
      });
    });
    if (watchElementRef.current) {
      intersectionObserver.observe(watchElementRef.current);
    }
  }, [watchElementRef]);

  return (
    <>
      <motion.div
        className="filterContainer"
        style={{ transformOrigin: "center" }}
        initial={{ opacity: 0, y: "-1-0%", scale: 1 }}
        animate={
          shouldBeAvailableAccordingToScroll || inputValue !== ""
            ? { opacity: 1, y: "0%", scale: 1 }
            : { opacity: 0, y: "-100%", scale: 1 }
        }
      >
        <div className="fakeSidebar"></div>
        <div className="wrapper withSidebar">
          <div className="inputContainer">
            {inputValue !== "" && (
              <motion.span
                style={{ transformOrigin: "center" }}
                initial={{ opacity: 0, scale: 2 }}
                animate={{ opacity: 1, scale: 1 }}
                whileHover={{ opacity: 1, scale: 1.2 }}
                className="emptyFilter"
                href="#"
                onClick={() => {
                  emptySearchField();
                }}
              >
                <img src="./img/icon_remove.svg" alt="" />
              </motion.span>
            )}

            <input
              className="filter"
              onChange={(e) => onInputChange(e.target.value)}
              value={inputValue}
              placeholder="doorzoek selectie"
              ref={inputElement}
            />
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default CijferIndexFilterContent;

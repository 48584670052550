import React from "react";

function Button({
  type = "simple",
  callBackFunction = null,
  color = "#e87647",
  text = "Lees meer",
  icon = "arrow",
}) {
  let backgroundColor = "transparent";
  let fontColor = color;
  let arrowColor = color;
  switch (type) {
    case "solid":
      backgroundColor = color;
      fontColor = "#fff";
      arrowColor = "#fff";
      break;
    default:
      break;
  }
  return (
    <div
      className={`button ${type}`}
      onClick={(e) => {
        if (callBackFunction !== null) {
          callBackFunction(e);
        }
      }}
      style={{
        backgroundColor,
        color: fontColor,
      }}
    >
      <span dangerouslySetInnerHTML={{ __html: text }} />
      {(icon === "arrow" || icon === "arrow-down") && (
        <svg
          width="19px"
          height="13px"
          viewBox="0 0 19 13"
          style={{
            transform: `rotate(${icon === "arrow-down" ? "90deg" : "0deg"}`,
          }}
        >
          <g transform="translate(0, 0)">
            <line
              x1="15.8914"
              y1="5.4559"
              x2="0.0004"
              y2="5.4559"
              stroke={arrowColor}
              strokeWidth="2"
            ></line>
            <polyline
              points="10.8108 0.7323 15.8918 5.4563 10.8108 10.1793"
              stroke={arrowColor}
              strokeWidth="2"
              fill="none"
            ></polyline>
          </g>
        </svg>
      )}
      {icon === "download" && (
        <svg viewBox="0 0 384 512" className="download">
          <path
            fill="#fff"
            d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm76.45 211.36l-96.42 95.7c-6.65 6.61-17.39 6.61-24.04 0l-96.42-95.7C73.42 337.29 80.54 320 94.82 320H160v-80c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v80h65.18c14.28 0 21.4 17.29 11.27 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"
          ></path>
        </svg>
      )}
    </div>
  );
}

export default Button;

import React, { useEffect, useState, useCallback } from "react";
import { motion, useMotionValue, useSpring } from "framer-motion";
import { useGlobalMouseMove } from "../../hooks/useWindowEvents";

const variants = {
  main: {
    initial: {},
    animate: {
      transition: { staggerChildren: 0.2, delayChildren: 0 },
    },
  },
  circle: {
    initial: { scale: 1.2, opacity: 0 },
    animate: {
      scale: 1,
      opacity: 1,
      transition: { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] },
    },
  },
  backgroundElements: {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { delay: 0.6 } },
  },
};
function PrerequisitesFacesSVG({
  forwardRef,
  active,
  setActive,
  images,
  hasVideo = [false, false, false],
  sectionInView,
}) {
  const updateMotionVars = useCallback((e) => {
    const percentX = (e.clientX / window.innerWidth) * 50;
    const percentY = (e.clientY / window.innerHeight) * 50;

    circleMotionValues[0].cx.set(backgroundCircles[0].cx + percentX);
    circleMotionValues[0].cy.set(backgroundCircles[0].cy + percentY);
  }, []);
  useGlobalMouseMove(updateMotionVars);

  let faceCircles = [];
  for (let i = 0; i < images.length; i++) {
    faceCircles[i] = {
      cx: 200 + 520 * i,
      cy: 350,
      r: images.length === 3 ? 140 : 100,
      background: images[i],
      hasVideo: hasVideo[i],
    };
  }

  const faceCirclesActiveState = [
    {
      cx: faceCircles[0].cx,
      cy: 350,
      r: images.length === 3 ? 140 : 100,
    },
    {
      cx: faceCircles[0].cx,
      cy: 350,
      r: images.length === 3 ? 140 : 100,
    },
    {
      cx: faceCircles[0].cx,
      cy: 350,
      r: images.length === 3 ? 140 : 100,
    },
  ];

  const faceCirclesSmallState = [
    {
      cx: 230,
      cy: 200,
      r: images.length === 3 ? 90 : 70,
    },
    {
      cx: 750,
      cy: 200,
      r: images.length === 3 ? 90 : 70,
    },
    {
      cx: 1270,
      cy: 250,
      r: images.length === 3 ? 90 : 70,
    },
  ];

  const defaultAnimationObject = {
    transition: { duration: 0.5 },
  };
  // overwrite according to state
  switch (active) {
    case 0:
      break;
    default:
      for (let i = 0; i < faceCircles.length; i++) {
        if (i === active - 1) {
          // active circle;
          faceCircles[i].cx = faceCirclesActiveState[i].cx;
          faceCircles[i].cy = faceCirclesActiveState[i].cy;
          faceCircles[i].r = faceCirclesActiveState[i].r;
        } else {
          if (i === 0) {
            faceCircles[i].cx = faceCirclesSmallState[active - 1].cx;
            faceCircles[i].cy = faceCirclesSmallState[active - 1].cy;
          } else {
            faceCircles[i].cx = faceCirclesSmallState[i].cx;
            faceCircles[i].cy = faceCirclesSmallState[i].cy;
          }

          faceCircles[i].r = faceCirclesSmallState[i].r;
        }
      }
      break;
  }

  let backgroundCircles = [{ cx: 450, cy: 200, r: 20, background: "#e87647" }];
  if (images.length > 2) {
    backgroundCircles.push({ cx: 950, cy: 400, r: 25, background: "#f2f3f5" });
  }
  const circleMotionValues = [
    {
      cx: useMotionValue(backgroundCircles[0].cx),
      cy: useMotionValue(backgroundCircles[0].cy),
    },
  ];

  const circleSpringValues = [
    {
      cx: useSpring(circleMotionValues[0].cx, { stiffness: 10 }),
      cy: useSpring(circleMotionValues[0].cy, { stiffness: 10 }),
    },
  ];

  let lines = [
    {
      x1: faceCircles[0].cx,
      x2: circleSpringValues[0].cx,
      y1: faceCircles[0].cy,
      y2: circleSpringValues[0].cy,
    },
    {
      x1: circleSpringValues[0].cx,
      x2: faceCircles[1].cx,
      y1: circleSpringValues[0].cy,
      y2: faceCircles[1].cy,
    },
  ];
  if (images.length > 2) {
    lines.push({
      x1: faceCircles[1].cx,
      x2: backgroundCircles[1].cx,
      y1: faceCircles[1].cy,
      y2: backgroundCircles[1].cy,
    });
    lines.push({
      x1: backgroundCircles[1].cx,
      x2: faceCircles[2].cx,
      y1: backgroundCircles[1].cy,
      y2: faceCircles[2].cy,
    });
  }

  return (
    <motion.svg
      viewBox={`0 0 ${images.length === 3 ? 1500 : 1000} 500`}
      className={`faces`}
      ref={forwardRef}
      variants={variants.main}
      initial="initial"
      animate={sectionInView ? "animate" : false}
    >
      <defs>
        {faceCircles.map((circle, index) => (
          <pattern
            key={`circle-pattern-${index}`}
            id={`faceCircle-${index}`}
            patternUnits="userSpaceOnUse"
            width="1500"
            height="500"
          >
            <image
              href={circle.background}
              x={circle.cx - circle.r}
              y={circle.cy - circle.r}
              width={circle.r * 2}
              height={circle.r * 2}
            ></image>
          </pattern>
        ))}
      </defs>
      {lines.map((line, index) => (
        <motion.line
          key={`line-${index}`}
          variants={variants.backgroundElements}
          x1={line.x1}
          x2={line.x2}
          y1={line.y1}
          y2={line.y2}
        />
      ))}
      {faceCircles.map((circle, index) => (
        <motion.g key={`facecircle-${index}`} variants={variants.circle}>
          {circle.hasVideo && index + 1 !== active && (
            <g className="playButton">
              <line
                x1={faceCircles[index].cx + faceCircles[index].r * 1.1}
                x2={faceCircles[index].cx}
                y1={faceCircles[index].cy - faceCircles[index].r * 1.1}
                y2={faceCircles[index].cy}
              />
              <motion.circle
                cx={faceCircles[index].cx + faceCircles[index].r * 1.1}
                cy={faceCircles[index].cy - faceCircles[index].r * 1.2}
                r={faceCircles[index].r / 4}
                initial={{ r: faceCircles[index].r / 4 }}
                onClick={() => {
                  setActive(index + 1);
                }}
                animate={{
                  r: faceCircles[index].r / 3,
                  transition: {
                    flip: Infinity,
                    ease: "linear",
                    duration: 2,
                  },
                }}
                whileHover={{
                  r: faceCircles[index].r / 2.5,
                  transition: {
                    flip: 0,
                    ease: "linear",
                    duration: 0.1,
                  },
                }}
              />
              {active === 0 && (
                <path
                  transform={
                    images.length < 3
                      ? "translate(790, 190) scale(0.8)"
                      : `translate(${
                          faceCircles[index].cx +
                          faceCircles[index].r * 1.1 -
                          50
                        }, ${
                          faceCircles[index].cy -
                          faceCircles[index].r * 1.2 -
                          50
                        })`
                  }
                  d="M40.6,29.4c-2.9-1.6-5.2-0.2-5.2,3.1v35.6c0,3.3,2.3,4.6,5.1,2.9
		l31.2-18.4c2.8-1.7,2.8-4.3-0.1-5.9L40.6,29.4z"
                />
              )}
              {active !== 0 && (
                <path
                  transform={`translate(${
                    faceCircles[index].cx + faceCircles[index].r * 1.1 - 50
                  }, ${
                    faceCircles[index].cy - faceCircles[index].r * 1.2 - 50
                  })`}
                  d="M47.3,39.4c-1.5-0.8-2.7-0.1-2.7,1.6v18.5c0,1.7,1.2,2.4,2.6,1.5l16.2-9.6c1.5-0.9,1.5-2.2-0.1-3.1L47.3,39.4z"
                />
              )}
            </g>
          )}
          <motion.circle
            initial={{
              cx: faceCircles[index].cx,
              cy: faceCircles[index].cy,
              r: faceCircles[index].r,
            }}
            animate={{
              ...defaultAnimationObject,
              cx: faceCircles[index].cx,
              cy: faceCircles[index].cy,
              r: faceCircles[index].r,
            }}
            // style={{ cx: faceCircles[index].cx, cy: faceCircles[index].cy }}
            fill="#e87647"
            stroke="#fff"
            strokeWidth="5"
          />
          <motion.circle
            initial={{
              cx: faceCircles[index].cx,
              cy: faceCircles[index].cy,
              r: faceCircles[index].r,
              opacity: 0.4,
            }}
            animate={{
              ...defaultAnimationObject,
              cx: faceCircles[index].cx,
              cy: faceCircles[index].cy,
              r: faceCircles[index].r,
              opacity: active === 0 || active === index + 1 ? 0.8 : 0.4,
            }}
            // style={{ opacity: 0.4 }}
            fill={`url(#faceCircle-${index})`}
            stroke="#fff"
            strokeWidth="0"
            onClick={() => {
              setActive(index + 1);
            }}
            className="faceCircle"
          />
        </motion.g>
      ))}
      {backgroundCircles.map((circle, index) => (
        <motion.circle
          key={`background-circle-${index}`}
          cx={circleSpringValues[0].cx}
          cy={circleSpringValues[0].cy}
          r={circle.r}
          fill={circle.background}
          className="backgroundCircle"
          variants={variants.backgroundElements}
        />
      ))}
    </motion.svg>
  );
}

export default PrerequisitesFacesSVG;

import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ArticleContent from "./articleContent";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { sendGoogleAnalyticsEvent } from "../helpers/helpers";

const Accordion = ({
  items,
  customClassName = "",
  showIntroductionWhenClosed = true,
  showButton = true,
}) => {
  const [currentlyActiveExampleItem, setCurrentlyActiveExampleItem] = useState(
    null
  );

  const location = useLocation();
  const URLquery = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <div className={`accordion ${customClassName}`}>
      {items.map((item, index) => {
        const isOpen =
          (index === currentlyActiveExampleItem ? true : false) || URLquery.pdf;
        return (
          <div
            className={`item ${isOpen ? "open" : ""} ${
              item.tekst_layout && item.tekst_layout.length > 0
                ? "hasContent"
                : ""
            }`}
            key={`accordion-item-${index}`}
          >
            <div
              className="accordionHeader"
              onClick={() => {
                if (item.tekst_layout && item.tekst_layout.length) {
                  // only if there actually is content to display
                  if (index === currentlyActiveExampleItem) {
                    setCurrentlyActiveExampleItem(null);
                  } else {
                    setCurrentlyActiveExampleItem(index);

                    sendGoogleAnalyticsEvent(
                      customClassName === "explanation"
                        ? "Open verdieping"
                        : "Open FAQ",
                      "click",
                      item.title
                    );
                  }
                }
              }}
            >
              {showButton && item.tekst_layout.length > 0 && (
                <div className={`arrow`} />
              )}

              <h4>{item.title}</h4>
              {showIntroductionWhenClosed && (
                <p dangerouslySetInnerHTML={{ __html: item.intro }}></p>
              )}
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: "auto" },
                    collapsed: { opacity: 0, height: 0 },
                  }}
                  className="content"
                  transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
                  //   transition={{ duration: 0.8 }}
                >
                  {!showIntroductionWhenClosed && (
                    <p dangerouslySetInnerHTML={{ __html: item.intro }}></p>
                  )}
                  {item.tekst_layout && (
                    <ArticleContent
                      content={item.tekst_layout || item.tijdlijn}
                    />
                  )}
                  <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;

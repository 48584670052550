import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Accordion from "./accordion";
import Audioplayer from "./audioPlayer";
import ThumbnailTegel from "./thumbnailTegel";

import ThumbnailStory from "./thumbnailStory";

const ArticleContent = ({ content }) => {
  const { categories, loading: categoriesAreLoading } = useSelector((state) => {
    return state.categories;
  });

  const layoutBlockVariants = {
    initial: { opacity: 0, y: "-50px" },
    animate: { opacity: 1, y: "0px" },
  };

  return (
    <>
      {content.map((textblok, index) => {
        switch (textblok.acf_fc_layout) {
          case "1_kolom":
            return (
              <motion.div
                variants={layoutBlockVariants}
                key={`afc_fc_layout_${index}`}
                dangerouslySetInnerHTML={{ __html: textblok.tekst }}
                className="layoutBlock paragraph"
              />
            );

          case "2_kolommen":
            return (
              <motion.div
                variants={layoutBlockVariants}
                key={`afc_fc_layout_${index}`}
              >
                <p dangerouslySetInnerHTML={{ __html: textblok.tekst }} />
                <p dangerouslySetInnerHTML={{ __html: textblok.tekst_2 }} />
              </motion.div>
            );

          case "4_kolommen":
            return (
              <motion.div
                variants={layoutBlockVariants}
                key={`afc_fc_layout_${index}`}
              >
                <p dangerouslySetInnerHTML={{ __html: textblok.tekst }} />
                <p dangerouslySetInnerHTML={{ __html: textblok.tekst_2 }} />
                <p dangerouslySetInnerHTML={{ __html: textblok.tekst_3 }} />
                <p dangerouslySetInnerHTML={{ __html: textblok.tekst_4 }} />
              </motion.div>
            );

          case "streamer":
            return (
              <motion.div
                variants={layoutBlockVariants}
                key={`afc_fc_layout_${index}`}
                className="layoutBlock streamer"
              >
                <span>{textblok.titel}</span>
              </motion.div>
            );

          case "titel":
            return (
              <motion.div
                variants={layoutBlockVariants}
                key={`afc_fc_layout_${index}`}
              >
                <h3>
                  <span>{textblok.titel}</span>
                </h3>
              </motion.div>
            );

          case "toelichting_titel":
            return (
              <motion.div
                variants={layoutBlockVariants}
                key={`afc_fc_layout_${index}`}
              >
                <h2>
                  <span>{textblok.titel}</span>
                </h2>
              </motion.div>
            );

          case "logos":
            return (
              <motion.div
                variants={layoutBlockVariants}
                key={`afc_fc_layout_${index}`}
                className="layoutBlock logos"
              >
                {textblok.logo &&
                  textblok.logo.map((item, logoindex) => {
                    return (
                      <div key={`logoindex_${logoindex}`}>
                        <img alt="" src={item.logo.url} />
                      </div>
                    );
                  })}
              </motion.div>
            );
          case "links":
            let linksColumn = textblok.links;
            return (
              <motion.div
                variants={layoutBlockVariants}
                key={`afc_fc_layout_${index}`}
                className="layoutBlock links"
              >
                <div>
                  {linksColumn &&
                    linksColumn.map((link, index) => {
                      return (
                        <Link
                          key={`${index}_${link["title-save"]}`}
                          to={link.link}
                        >
                          {link["link_titel"]}
                        </Link>
                      );
                    })}
                </div>
              </motion.div>
            );
          case "tijdlijn":
            return (
              <motion.div
                variants={layoutBlockVariants}
                key={`afc_fc_layout_${index}`}
                className="layoutBlock timeline"
              >
                <table>
                  <tbody>
                    <tr className="header">
                      <th>Jaar</th>
                      <th>Geschillencommissie</th>
                      <th>{textblok.header_participant}</th>
                    </tr>

                    {textblok.tijdlijn.map((item) => {
                      return (
                        <tr key={item.jaar + item.commissie}>
                          <td>{item.jaar}</td>
                          <td>{item.commissie}</td>
                          <td>{item.participant}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </motion.div>
            );

          case "bezetting_commissies":
            return (
              <motion.div
                variants={layoutBlockVariants}
                key={`afc_fc_layout_${index}`}
                className="layoutBlock bezetting-commissies"
              >
                {textblok.commissies.map((item, commissieIndex) => {
                  return (
                    <div key={commissieIndex}>
                      <strong>{item.naam}</strong>
                      <div className="names open">
                        <div
                          dangerouslySetInnerHTML={{ __html: item.kolom_1 }}
                        ></div>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.kolom_2 }}
                        ></div>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.kolom_3 }}
                        ></div>
                      </div>
                    </div>

                    // <CommissieBezettingElement
                    //   key={item.naam}
                    //   naam={item.naam}
                    //   kolom_1={item.kolom_1}
                    //   kolom_2={item.kolom_2}
                    //   kolom_3={item.kolom_3}
                    //   isotopeReArrange={this.props.isotopeReArrange}
                    // />
                  );
                })}
              </motion.div>
            );
          case "verdiepingsblok":
            return (
              <div
                key={`afc_fc_layout_${index}`}
                className={`layoutBlock verdieping ${
                  textblok.afbeelding_weergeven ? "withImage" : ""
                }`}
              >
                {textblok.afbeelding_weergeven && textblok.afbeelding ? (
                  <img src={textblok.afbeelding} alt="" />
                ) : null}
                <motion.div
                  variants={layoutBlockVariants}
                  key={`afc_fc_layout_${index}`}
                >
                  <span>{textblok.label}</span>
                  <h3>{textblok.titel}</h3>
                  <p dangerouslySetInnerHTML={{ __html: textblok.tekst }} />
                </motion.div>
              </div>
            );
          case "toelichting_term":
            const items = [
              {
                title: textblok.title,
                tekst_layout: textblok.tekst_layout,
              },
            ];
            return (
              <motion.div
                variants={layoutBlockVariants}
                key={`afc_fc_layout_${index}`}
                className="layoutBlock explanation"
              >
                <Accordion
                  items={items}
                  customClassName="explanation"
                  showButton={false}
                  showIntroductionWhenClosed={false}
                />
              </motion.div>
            );

          case "link_to_other_article":
            return (
              <motion.div
                variants={layoutBlockVariants}
                key={`afc_fc_layout_${index}`}
                className="layoutBlock link_to_other_article"
              >
                <p>
                  <span>
                    <span>{textblok.title}</span>
                  </span>
                </p>
                <div className="links">
                  {textblok.links_to.map((link, linkIndex) => {
                    switch (link.type) {
                      case "verhaal":
                        return (
                          <div
                            key={`storyThumbnail-${linkIndex}`}
                            className={`storyThumbnail`}
                          >
                            <ThumbnailStory size="small" story={link} />
                          </div>
                        );
                      case "tegel":
                        return (
                          <>
                            {!categoriesAreLoading &&
                              categories[link.titleSave] && (
                                <ThumbnailTegel
                                  displaySize="small"
                                  article={categories[link.titleSave]}
                                />
                              )}
                          </>
                        );
                      default:
                        return false;
                    }
                  })}
                </div>
              </motion.div>
            );

          case "afbeeldingen":
            return (
              <motion.div
                variants={layoutBlockVariants}
                key={`afc_fc_layout_${index}`}
                className="layoutBlock images"
              >
                {textblok.afbeeldingen.map((image, index) => (
                  <div key={`image-${index}`}>
                    <img src={image} alt="" />
                  </div>
                ))}
              </motion.div>
            );
          case "video_embed":
            return (
              <motion.div
                variants={layoutBlockVariants}
                key={`afc_fc_layout_${index}`}
                className="layoutBlock videoEmbed"
                dangerouslySetInnerHTML={{ __html: textblok.video }}
              ></motion.div>
            );
          case "audio":
            return (
              <motion.div
                variants={layoutBlockVariants}
                key={`afc_fc_layout_${index}`}
                className="layoutBlock audio"
              >
                <Audioplayer url={textblok.url} />
              </motion.div>
            );

          default:
            return (
              <div key={`afc_fc_layout_${index}`}>
                TEKSTBLOK LAYOUT NOT DEFINED: {textblok.acf_fc_layout}
                <i></i>
              </div>
            );
        }
      })}
    </>
  );
};

export default ArticleContent;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchStories } from "../redux/actions";
import { Link } from "react-router-dom";
import LazyLoadBackgroundImage from "./lazyloadBackgroundImage";
import { motion } from "framer-motion";
import ThumbnailStory from "./thumbnailStory";
function StoryOverview({
  children,
  hideArticleID,
  thumbnailSize = "compact",
  sectionInView = true,
}) {
  const { stories, loading } = useSelector((state) => {
    return state.stories;
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStories());
  }, []);

  const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] };

  const storyThumbnailVariants = {
    initial: { scale: 0.9, opacity: 0 },
    animate: { scale: 1, opacity: 1, transition },
    exit: {
      scale: 0.5,
      opacity: 0,
      transition: { duration: 1.5, ...transition },
    },
  };

  if (!sectionInView) {
    return false;
  }
  return (
    <div
      className={`storiesOverview ${children ? "withIntro" : ""} ${
        loading ? "loading" : ""
      }`}
      key="stories"
    >
      {children && <div>{children}</div>}

      {!children && (
        <h4>
          <span>Meer achtergronden</span>
        </h4>
      )}
      {loading && (
        // empty articles
        <></>
      )}
      {!loading &&
        stories.map((story, index) => {
          if (story.id === hideArticleID) {
            return false;
          }
          return (
            <ThumbnailStory
              story={story}
              size={thumbnailSize}
              key={`thumbnailStory-${index}`}
              delay={0.1 + index * 0.1}
            />
          );
        })}
    </div>
  );
}

export default StoryOverview;

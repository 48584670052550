import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";
function VideoVoorwoord() {
  return (
    <motion.div
      exit={{ opacity: 0 }}
      key={`page-voorwoord}`}
      className="page voorwoord"
    >
      <div className="wrapper">
        <div className="pageHeader">
          <Link to="/">
            <h1 className="logo">
              samen
              <br />
              werken aan
              <br />
              kwaliteit
            </h1>
          </Link>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, delay: 0.2 }}
          className="videoContainer"
        >
          <video
            src="https://www.samenwerkenaankwaliteit.nl/jaarverslag-2023/jacqueline-berkelaar-2023.mp4"
            controls="controls"
            autoPlay
          ></video>
        </motion.div>
        <div className="columns">
          <div>
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              alt="Logo De Geschillencommissie Jaarverslagen 2023"
              src="./img/jaarverslagen2023.svg"
            />
          </div>
          <div>
            <motion.div className="buttonContainer">
              <a href="/">NAAR HET JAARVERSLAG</a>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default VideoVoorwoord;

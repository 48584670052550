import * as types from "../actionTypes";

const initialState = {
  loading: true,
  introduction: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_INTRODUCTION:
      return { ...state, loading: false, introduction: action.payload };

    default:
      return state;
  }
}

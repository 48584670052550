import { combineReducers } from "redux";
// import { routerReducer } from 'react-router-redux'
import articleReducer from "./reducer_articles";
import articleFilter from "./reducer_article_filter";
import article from "./reducer_article";
import story from "./reducer_story";
import page from "./reducer_page";
import { activeCategories, categories, tags } from "./reducer_categories";
import { homepageData } from "./reducer_homepage";
import { stories } from "./reducer_stories";
import { searchQuery } from "./reducer_search";
import introduction from "./reducer_introduction";
import { faqs } from "./reducer_faqs";
import {
  searchResults,
  searchViewVisible,
  searchStatus,
  currentSearchTerm,
} from "./reducer_search_lookup";

const rootReducer = combineReducers({
  homepageData,
  article,
  story,
  page,
  categories,
  faqs,
  stories,
  searchStatus,
  searchResults,
  searchViewVisible,
  currentSearchTerm,
  introduction,
});

export default rootReducer;

import * as types from "../actionTypes";

const initialState = {
  loading: true,
  story: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_ACTIVE_STORY:
      return { ...state, loading: false, story: action.payload };

    case types.UNLOAD_STORY:
      return { ...state, loading: true, story: {} };

    default:
      return state;
  }
}

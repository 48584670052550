import React, { useState } from "react";
import Header from "./header";
import Footer from "./footer";
import Sidebar from "./sidebar";
import ArticleHeader from "./svgComponents/articleHeader";
import { AnimatePresence } from "framer-motion";
import SearchResults from "./searchResults";
import { motion } from "framer-motion";
import { ReactComponent as NetworkSVG } from "./svgComponents/networkbackground.svg";

const Layout = (props) => {
  const { history } = props;
  const [barheight, setBarheight] = useState(
    history.location.pathname === "/" ? "20vh" : `${2 + 3.6}em`
  );
  const [withImage, setWithImage] = useState(
    history.location.pathname === "/" ? true : false
  );
  history.listen((location, action) => {
    setBarheight(location.pathname === "/" ? "20vh" : `${2 + 3.6}em`);
    setWithImage(location.pathname === "/" ? true : false);
  });
  return (
    <>
      <motion.div
        className={`appHeader ${withImage ? "withImage" : ""}`}
        initial={{ height: "20vh" }}
        animate={{
          height: barheight,
        }}
        transition={{ duration: 0.5 }}
      />
      {withImage && <div className="networkBackground"></div>}
      <div className="articleHeaderContainer">
        <ArticleHeader history={history} />
      </div>
      {/* <Header /> */}
      <div className="pageWrapper">
        <Sidebar
          history={history}
          // key={`sidebar-${history.location.pathname}`}
        />

        <div>{props.children}</div>
      </div>
      <Footer />
      <AnimatePresence exitBeforeEnter={true}>
        <SearchResults />
      </AnimatePresence>
    </>
  );
};

export default Layout;

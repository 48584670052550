import * as types from "../actionTypes";

const initialStoriesState = {
  loading: true,
  stories: [],
};
export function stories(state = initialStoriesState, action) {
  switch (action.type) {
    case types.FETCH_STORIES:
      return {
        ...state,
        loading: false,
        stories: action.payload.stories,
      };
    default:
      return state;
  }
}

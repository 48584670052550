import React, { Component, useContext } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { createHomeLink } from "../helpers/helpers.js";
import { categoryStore } from "../categoryStore";
import { articleStore } from "../articleStore";

const Breadcrumbs = ({ onClickItem }) => {
  const history = useHistory();

  /* LOAD GLOBAL STATES */
  const { stories, storiesAreLoading } = useSelector((state) => {
    return state.stories;
  });
  const { categories, loading: categoriesAreLoading } = useSelector((state) => {
    return state.categories;
  });

  let jaarverslag = null;
  let breadcrumbTerms = [];
  let viewMode = null;
  if (history.location.pathname) {
    breadcrumbTerms = history.location.pathname.split("/"); //substr haalt eerste slash weg
    if (
      breadcrumbTerms.length &&
      breadcrumbTerms[breadcrumbTerms.length - 1] === ""
    ) {
      breadcrumbTerms.splice(-1, 1); // laatste is leeg ivm trailing slash
    }
    if (breadcrumbTerms.length) {
      if (breadcrumbTerms[0] === "") {
        breadcrumbTerms.splice(0, 1); // eerst is leeg ivm prefix slash
      }
      switch (breadcrumbTerms[0]) {
        case "inhoud":
          viewMode = "cijferIndex";
          if (breadcrumbTerms.length > 0) {
            jaarverslag = breadcrumbTerms[1];
          } else {
            return false;
          }
          break;
        case "achtergrond":
          viewMode = "achtergrond";
          break;
        default:
          return <></>;
      }
      breadcrumbTerms.splice(0, 1); // remove "inhoud/achtergrond" from the array
    }
  }
  let currentlyActiveTerm = breadcrumbTerms[breadcrumbTerms.length - 1];

  return (
    <div className={`breadcrumbs viewMode-${viewMode} in-${jaarverslag}`}>
      <ul className="breadcrumbs">
        <li className="home">
          <Link
            to="/"
            onClick={() => {
              onClickItem();
            }}
          >
            <div className="circle" />
            <span>
              De Geschillencommissie
              <span>Jaarverslagen 2023</span>
            </span>
          </Link>
        </li>

        {viewMode === "cijferIndex" &&
          breadcrumbTerms.map((term, index) => {
            return (
              <li key={categories[term]["id"]}>
                <Link
                  to={categories[term].link}
                  onClick={() => {
                    onClickItem();
                  }}
                >
                  <div className="circle"></div>
                  <span>{categories[term].name}</span>
                </Link>
              </li>
            );
          })}
        {viewMode === "achtergrond" && (
          <li className="intro">
            <span>
              Een jaar lang kwaliteit; <br />
              achtergrond interviews
            </span>
          </li>
        )}
        {viewMode === "achtergrond" &&
          stories.map((story) => {
            return (
              <li
                key={story.id}
                className={`${
                  currentlyActiveTerm === story["title-save"] ? "active" : ""
                }`}
              >
                <Link
                  to={story.link}
                  onClick={() => {
                    onClickItem();
                  }}
                >
                  <div className="circle"></div>
                  <span>{story.name}</span>
                </Link>
              </li>
            );
          })}

        {/* breadcrumbTerms.map((term, index) => {
            return (
              <li key={categories[term]["id"]}>
                <Link to={categories[term].link}>
                  <div className="circle"></div>
                  <span>{categories[term].name}</span>
                </Link>
              </li>
            );
          })} */}
      </ul>
    </div>
  );
};

export default Breadcrumbs;

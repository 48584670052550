import React, { useCallback } from "react";
import { useGlobalMouseMove } from "../../hooks/useWindowEvents";
import {
  motion,
  useViewportScroll,
  useMotionValue,
  useSpring,
  useTransform,
} from "framer-motion";

const variants = {
  main: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: { delay: 0.2, when: "beforeChildren", staggerChildren: 0.1 },
    },
  },
  circle: {
    initial: {
      opacity: 0,
      scale: 2,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.4, ease: [0.43, 0.13, 0.23, 0.96] },
    },
  },
};

const PrerequisiteSVG2023 = ({
  backgroundImage,
  sectionInView,
  sectionEnd,
  figureRef,
}) => {
  /* PARALLAX */
  const circle = {
    cx: 250,
    cy: 520,
    r: 180,
    background: backgroundImage,
  };

  const circleMotionValue = {
    cx: useMotionValue(circle.cx),
    cy: useMotionValue(circle.cy),
    deviationX: useMotionValue(0),
    deviationY: useMotionValue(0),
  };
  const springConfig = {
    stiffness: 50,
    mass: 1.5,
  };

  const textMotionValues = {
    deviationX: useMotionValue(0),
    deviationY: useMotionValue(0),
  };

  const springValueText = {
    deviationX: useSpring(textMotionValues.deviationX, springConfig),
    deviationY: useSpring(textMotionValues.deviationY, springConfig),
  };

  const springValue = {
    cx: useSpring(circleMotionValue.cx, springConfig),
    cy: useSpring(circleMotionValue.cy, springConfig),
    deviationX: useSpring(circleMotionValue.deviationX, springConfig),
    deviationY: useSpring(circleMotionValue.deviationY, springConfig),
  };

  const updateMotionVars = useCallback((e) => {
    const percentX = (e.clientX / window.innerWidth) * 50;
    const percentY = (e.clientY / window.innerHeight) * 50;
    circleMotionValue.cx.set(circle.cx + percentX);
    circleMotionValue.cy.set(circle.cy + percentY);
    springValueText.deviationX.set(percentX * 0.5);
    springValueText.deviationY.set(percentY * 0.6);
  }, []);
  useGlobalMouseMove(updateMotionVars);
  return (
    <div ref={figureRef}>
      <motion.svg
        variants={variants.main}
        initial="initial"
        animate={sectionInView ? "animate" : false}
        style={{ originX: 0, originY: 1 }}
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 750 750"
      >
        <defs>
          <pattern
            key={`prerequisite-circle-pattern`}
            id={`prerequisite-circle`}
            patternUnits="userSpaceOnUse"
            width="750"
            height="750"
          >
            <image
              href={circle.background}
              x={circle.cx - circle.r}
              y={circle.cy - circle.r}
              width={circle.r * 2}
              height={circle.r * 2}
            ></image>
          </pattern>
        </defs>
        {/* 
        {circles.map((circle, index) => (
          <motion.g key={`circle-${index}`} variants={variants.circle}>
            <motion.circle
              style={{
                cx: springValues[index].cx,
                cy: springValues[index].cy,
                r: circles[index].r,
                stroke: circle.stroke ? circle.stroke : "",
              }}
              cx={springValues[index].cx}
              cy={springValues[index].cy}
              r={circles[index].r}
              fill={
                circle.background
                  ? `url(#prerequisite-circle-${index})`
                  : "#e87647"
              }
            />
          </motion.g>
        ))} */}
        <motion.g
          style={{
            x: springValueText.deviationX,
            y: springValueText.deviationY,
          }}
        >
          <text x="50%" y="50%" textAnchor="middle">
            2023
          </text>
        </motion.g>
        {/* <motion.g variants={variants.circle}>
          <motion.circle
            style={{
              // cx: springValue.cx,
              // cy: springValue.cy,
              // r: circle.r,
              stroke: circle.stroke ? circle.stroke : "",
            }}
            cx={circle.cx}
            cy={circle.cy}
            r={circle.r}
            fill={circle.background ? `url(#prerequisite-circle)` : "#e87647"}
          />
        </motion.g> */}
      </motion.svg>
    </div>
  );
};

export default PrerequisiteSVG2023;

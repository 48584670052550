import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import { fetchPage } from "../redux/actions";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";
function Page() {
  const match = useRouteMatch();
  let pageSlug = "";
  if (match.params.slug) {
    pageSlug = match.params.slug;
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPage(pageSlug));
  }, [match.params.slug]);

  const { page, loading: pageIsLoading } = useSelector((state) => {
    return state.page;
  });

  if (page === "404") {
  }

  return (
    <motion.div exit={{ opacity: 0 }} key={`page-${pageSlug}`} className="page">
      <div className="wrapper">
        <div className="pageHeader">
          <Link to="/">
            <h1 className="logo">
              samen
              <br />
              werken aan
              <br />
              kwaliteit
            </h1>
          </Link>
        </div>

        {pageIsLoading ? (
          <div>
            <h1 className="pageTitle">{pageSlug}</h1>

            <img className="loadingIcon" src="./img/loading.svg" alt="" />
          </div>
        ) : (
          <div>
            <h1 className="pageTitle">{page.title}</h1>
            {page.pagina_content_blokken
              ? page.pagina_content_blokken?.map((textblok, index) => {
                  switch (textblok.acf_fc_layout) {
                    default:
                    case "tekst":
                      return (
                        <motion.div
                          key={`afc_fc_layout_${index}`}
                          dangerouslySetInnerHTML={{ __html: textblok.tekst }}
                          className="layoutBlock paragraph"
                        />
                      );
                    case "colofon":
                      return (
                        <motion.div
                          key={`afc_fc_layout_${index}`}
                          className="layoutBlock colofon"
                        >
                          {textblok.persoon?.map((person, i) => (
                            <div className="row">
                              <div className="name">{person.naam}</div>
                              <div className="role">{person.rol}</div>
                            </div>
                          ))}
                        </motion.div>
                      );
                    case "afbeelding":
                      return (
                        <img
                          src={textblok.afbeelding}
                          style={{ maxWidth: "100%" }}
                        />
                      );
                  }
                })
              : null}
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default Page;

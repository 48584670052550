//https://blog.logrocket.com/use-hooks-and-context-not-react-and-redux/
import React, { createContext, useReducer } from "react";

const initialState = { loading: true };
const categoryStore = createContext(initialState);
const { Provider } = categoryStore;

const CategoryStateProvider = ({ children }) => {
  const [categoryState, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "setCategoryData":
        return { ...state, loading: false, ...action.data };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ categoryState, dispatch }}>{children}</Provider>;
};

export { categoryStore, CategoryStateProvider };

import { motion } from "framer-motion";
import React, { useCallback } from "react";
import ArchiveLinksSVG from "./svgComponents/archiveLinksSVG";

const variants = {
  wrapper: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: { delay: 0.6, staggerChildren: 0.5 },
    },
  },
  content: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
  },
  yearwrapper: {
    initial: {
      opacity: 1,
    },
    animate: {
      opacity: 1,
      transition: { staggerChildren: 0.1, delayChildren: 0.8 },
    },
  },
  year: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
  },
};

const ArchiveLinks = ({ homepageData, sectionInView }) => {
  return (
    <motion.div
      initial="initial"
      animate={sectionInView ? "animate" : false}
      variants={variants.wrapper}
    >
      <div className="columns">
        <div className="text">
          <motion.h2 variants={variants.content}>
            {homepageData.terug_in_de_tijd_titel}
          </motion.h2>
          <motion.p variants={variants.content}>
            {homepageData.terug_in_de_tijd_tekst}
          </motion.p>
        </div>
        <div>
          <motion.div className="links" variants={variants.yearwrapper}>
            <div className="links__container">
              {[...Array(6)].map((x, i) => {
                const year = 2023 - i - 1;
                const url = `https://www.samenwerkenaankwaliteit.nl/jaarverslag-${year}/`;
                return (
                  <motion.div
                    variants={variants.year}
                    className="links__link"
                    key={`year-${i}`}
                  >
                    <a href={url} className="link__link" target="_blank">
                      {year}
                    </a>
                  </motion.div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
      <motion.p variants={variants.content} className="disclaimer">
        {homepageData.terug_in_de_tijd_disclaimer}
      </motion.p>
    </motion.div>
  );
};
export default ArchiveLinks;

import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSearchViewVisible } from "../redux/actions";
import { searchLookup, emptySearchResults } from "../redux/actions";
import SearchSuggestions from "../components/searchSuggestions";
import SearchResults from "./searchResults";
import useThrottledEffect from "use-throttled-effect";
import { sendGoogleAnalyticsEvent } from "../helpers/helpers";

const SearchField = ({
  setFocus = false,
  fakeMobileButton = false,
  closeMobileMenu = () => {},
}) => {
  /* DERIVE FROM REDUX STATE */
  const searchViewVisible = useSelector((state) => {
    return state.searchViewVisible;
  });

  const inputElement = useRef(null);
  if (searchViewVisible && inputElement.current) {
    inputElement.current.focus();
  }

  const currentSearchTerm = useSelector((state) => {
    return state.currentSearchTerm;
  });

  const [inputValue, setInputValue] = useState(
    currentSearchTerm ? currentSearchTerm : ""
  );

  useThrottledEffect(
    () => {
      if (inputValue.length > 2) {
        sendGoogleAnalyticsEvent("search", "type", inputValue);

        dispatch(searchLookup(inputValue));
      }
    },
    200,
    [inputValue]
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (setFocus) {
      inputElement.current.focus();
    }
  }, [inputValue]);

  const handleInputChange = () => {
    let query = inputElement.current.value.toLowerCase();
    setInputValue(inputElement.current.value);
  };

  const emptySearchField = () => {
    setInputValue("");
    dispatch(setSearchViewVisible(false));
  };

  return (
    <div
      className={`searchField ${searchViewVisible ? "searchViewVisible" : ""}`}
    >
      <div className="inputContainer">
        {/* {inputValue.length > 0 && (
          <span
            className="emptySearch"
            href="#"
            onClick={() => {
              emptySearchField();
            }}
          >
            <img src="./img/icon_remove.svg" alt="" />
          </span>
        )} */}

        <input
          type="text"
          ref={inputElement}
          placeholder="Doorzoek de jaarverslagen"
          value={inputValue}
          onChange={handleInputChange}
        />
        <svg width="19px" height="13px" viewBox="0 0 19 13">
          <g transform="translate(0, 0)">
            <line
              x1="15.8914"
              y1="5.4559"
              x2="0.0004"
              y2="5.4559"
              stroke="#e87647"
              strokeWidth="2"
            ></line>
            <polyline
              points="10.8108 0.7323 15.8918 5.4563 10.8108 10.1793"
              stroke="#e87647"
              strokeWidth="2"
              fill="none"
            ></polyline>
          </g>
        </svg>
        {fakeMobileButton && (
          <div
            className="mobileButton"
            onClick={() => {
              closeMobileMenu();
              dispatch(setSearchViewVisible(true));
            }}
          />
        )}
      </div>
      {inputValue.length > 0 && inputValue.length < 3 && (
        <div className=" helperText">type meer om te zoeken...</div>
      )}
    </div>
  );
};

export default SearchField;

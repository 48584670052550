//https://blog.logrocket.com/use-hooks-and-context-not-react-and-redux/
import React, { createContext, useReducer } from "react";

const initialState = { loading: true, pageContent: {} };
const articleStore = createContext(initialState);
const { Provider } = articleStore;

const ArticleStateProvider = ({ children }) => {
  const [articleState, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "setLoadingArticle":
        return { ...state, loading: true };
      case "changeLoadedArticle":
        return { ...state, loading: false, pageContent: action.data };
      case "removeHeader":
        let pageContent = state.pageContent;
        pageContent.header_afbeelding = null;
        return { ...state, loading: false, pageContent: pageContent };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ articleState, dispatch }}>{children}</Provider>;
};

export { articleStore, ArticleStateProvider };

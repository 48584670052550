import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";

export default function LazyLoadBackgroundImage({
  src,
  useClassName = "imageContainer",
}) {
  const [imageSrc, setImageSrc] = useState(null);
  const elementRef = useRef(null);
  let loaded = false;
  let style = {};
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const { isIntersecting } = entry;
        if (isIntersecting) {
          const imageLoader = new Image();
          imageLoader.src = src;
          imageLoader.onload = () => {
            setImageSrc(src);
          };
          intersectionObserver.disconnect();
        }
      });
    });
    intersectionObserver.observe(elementRef.current);
  }, []);

  if (imageSrc) {
    loaded = true;
    style = { backgroundImage: `url(${imageSrc})` };
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: loaded ? 1 : 0 }}
      transition={{ duration: 0.5, delay: 0.4 }}
      ref={elementRef}
      className={useClassName}
      style={style}
    ></motion.div>
  );
}

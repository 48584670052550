import React from "react";
import SearchField from "../components/searchfield";

function Search() {
  return (
    <div className="wrapper">
      <SearchField />
    </div>
  );
}

export default Search;

import { useTransform, useViewportScroll, motion } from "framer-motion";
import React, { useLayoutEffect, useState, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useWindowSize } from "../hooks/useWindowSize";
const variants = {
  wrapper: {
    initial: {},
    animate: {
      transition: {
        staggerChildren: 0.25,
      },
    },
  },
  content: {
    initial: {
      opacity: 0,
      scale: 1.2,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: { duration: 1 },
    },
  },
  image: {
    initial: {
      opacity: 0,
      scale: 0.7,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: { delay: 0.5, duration: 1 },
    },
  },
};

const BrandExplainer = ({ homepageData, sectionInView, scrollY }) => {
  const [sectionOffset, setSectionOffset] = useState({ start: 0, end: 0 });
  const sectionRef = useRef();
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  let paralaxOffset = useTransform(
    scrollY,
    [sectionOffset.start, sectionOffset.end],
    ["0%", "-30%"]
  );
  let scale = useTransform(
    scrollY,
    [sectionOffset.start, sectionOffset.end],
    [1.3, 1]
  );
  let opacity = useTransform(
    scrollY,
    [
      sectionOffset.start,
      // sectionOffset.start + (sectionOffset.end - sectionOffset.start) / 2,
      sectionOffset.end,
    ],
    [0, 0.5]
  );

  const windowDimensions = useWindowSize();
  useLayoutEffect(() => {
    if (!sectionRef.current) return;

    const onResize = () => {
      const offset = sectionRef.current.offsetParent.offsetTop;
      const height = sectionRef.current.offsetHeight;
      setSectionOffset({
        start: offset - window.innerHeight,
        end: offset + height,
      });
    };

    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [windowDimensions]);

  return (
    <motion.div
      ref={sectionRef}
      variants={variants.wrapper}
      initial="initial"
      animate={sectionInView ? "animate" : false}
    >
      <div
        className="backgroundImage"
        style={{
          backgroundImage: `url(${homepageData.toelichting_samen_werken_aan_kwaliteit.background_image})`,
        }}
      />
      <div ref={ref} className="columns">
        <div className="content">
          <motion.h1 variants={variants.content}>
            {homepageData.toelichting_samen_werken_aan_kwaliteit.titel}
          </motion.h1>
          <motion.p
            variants={variants.content}
            dangerouslySetInnerHTML={{
              __html: homepageData.toelichting_samen_werken_aan_kwaliteit.tekst,
            }}
          />

          {homepageData.toelichting_samen_werken_aan_kwaliteit_download
            .button_tekst ? (
            <div className="download">
              <motion.div variants={variants.image} className="buttonContainer">
                <a
                  target="_blank"
                  href={
                    homepageData.toelichting_samen_werken_aan_kwaliteit_download
                      .download_file
                  }
                >
                  {
                    homepageData.toelichting_samen_werken_aan_kwaliteit_download
                      .button_tekst
                  }
                </a>
              </motion.div>
            </div>
          ) : null}
        </div>
      </div>
    </motion.div>
  );
};
export default BrandExplainer;

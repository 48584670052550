import * as types from "../actionTypes";

const initialState = {
  loading: true,
  article: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_ACTIVE_ARTICLE:
      return { ...state, loading: false, article: action.payload };

    case types.UNLOAD_ARTICLE:
      return { ...state, loading: true, article: {} };

    default:
      return state;
  }
}

import React, { useState, useLayoutEffect, useRef } from "react";
import PrerequisiteSVG2023 from "../components/svgComponents/prerequisiteSVG2023";
import { motion, useTransform } from "framer-motion";
import { useWindowSize } from "../hooks/useWindowSize";

const variants = {
  main: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: { delay: 0.6, staggerChildren: 0.3 },
    },
  },
  content: {
    initial: {
      opacity: 0,
      originX: 0,
      originY: 0,
    },
    animate: {
      opacity: 1,
      scale: 1,
    },
  },
};
const Prerequisite2023 = ({ homepageData, sectionInView, scrollY }) => {
  const [sectionOffset, setSectionOffset] = useState({ start: 0, end: 0 });
  const sectionRef = useRef();
  const figureRef = useRef();
  const windowDimensions = useWindowSize();
  useLayoutEffect(() => {
    if (!sectionRef.current || !figureRef.current) return;

    const onResize = () => {
      const offset = sectionRef.current.offsetParent.offsetTop;
      const height = sectionRef.current.offsetHeight;
      const svgHeight = figureRef.current.offsetHeight * 0.25;

      setSectionOffset({
        end: offset + height,
        height: height - svgHeight,
      });
    };

    onResize();
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, [windowDimensions]);

  const parallaxScroll = useTransform(
    scrollY,
    [0, sectionOffset.end],
    [0, sectionOffset.height * 0.8]
  );

  const parallaxScrollText = useTransform(
    scrollY,
    [0, sectionOffset.end],
    [0, sectionOffset.height * 0.6]
  );

  return (
    <>
      <motion.div
        ref={sectionRef}
        variants={variants.main}
        initial="initial"
        animate={sectionInView ? "animate" : false}
      >
        <div className="backgroundImage"></div>
        <div className="columns">
          <div className="content">
            <motion.h1
              variants={variants.content}
              dangerouslySetInnerHTML={{
                __html: homepageData.introductie.titel,
              }}
            />
            <motion.h2 variants={variants.content}>
              {homepageData.introductie.onder_titel}
            </motion.h2>

            <motion.p
              variants={variants.content}
              className="introduction"
              dangerouslySetInnerHTML={{
                __html: homepageData.introductie.introductie,
              }}
            />
          </div>
          <div className="figure">
            <motion.div
              className="textCircle"
              style={{
                y: parallaxScrollText,
                background: "white",
                borderRadius: "50%",
                width: "45%",
                paddingTop: "45%",
                position: "absolute",
                bottom: "20%",
                left: "10%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                border: "1px solid #847e79",
                backgroundImage: `url(${homepageData.introductie.afbeelding_bol})`,
                zIndex: 10,
              }}
            />

            <motion.div style={{ y: parallaxScroll }}>
              <PrerequisiteSVG2023
                figureRef={figureRef}
                backgroundImage={homepageData.introductie.afbeelding_bol}
                sectionInView={sectionInView}
              />
            </motion.div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Prerequisite2023;

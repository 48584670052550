import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";

import LazyLoadBackgroundImage from "./lazyloadBackgroundImage";
import { motion } from "framer-motion";
const ThumbnailTegel = ({
  article,
  displaySize,
  emptySearchResults = () => {},
}) => {
  const {
    name: articleName,
    type: articleType,
    link: articleLink,
    image: articleImage,
    children: articleChildren,
  } = article;
  const dispatch = useDispatch();

  /* FRAMER MOTION */
  const variants = {
    initial:
      displaySize === "small"
        ? {}
        : {
            borderRadius: "0 0 50% 50%",
          },
    enter: {
      borderRadius: "0 0 50% 50%",
    },
    hover: {
      borderRadius: "0 0 0% 0%",
      transition: { duration: 0.5 },
      // opacity: 0,
    },
  };

  return (
    <Link
      to={articleLink}
      className={`tegel ${displaySize}`}
      onClick={() => {
        emptySearchResults();
      }}
    >
      <div className="image">
        <motion.div className="imageWrapper" variants={variants}>
          <LazyLoadBackgroundImage src={articleImage} />
        </motion.div>
      </div>
      <div className="content">
        <span className="type">{articleType}</span>
        <h1>{articleName}</h1>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          width="19px"
          height="13px"
          viewBox="0 0 19 13"
          className="arrow"
        >
          <line
            x1="15.8914"
            y1="5.4559"
            x2="0.0004"
            y2="5.4559"
            stroke="#e87647"
            strokeWidth="2"
          ></line>
          <polyline
            points="10.8108 0.7323 15.8918 5.4563 10.8108 10.1793"
            stroke="#e87647"
            strokeWidth="2"
            fill="none"
          ></polyline>
        </svg>
      </div>
    </Link>
  );
};

export default ThumbnailTegel;

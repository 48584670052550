import * as types from "../actionTypes";

export function searchResults(state = [], action) {
  switch (action.type) {
    case types.SEARCHLOOKUP:
      return action.payload;
    case types.EMPTYSEARCHRESULTS:
      return [];
    default:
      return state;
  }
}

export function searchViewVisible(state = false, action) {
  switch (action.type) {
    case types.SEARCH_VIEW_VISIBLE:
      return action.payload;
    default:
      return state;
  }
}

export function currentSearchTerm(state = "", action) {
  switch (action.type) {
    case types.CURRENT_SEARCHTERM:
      return action.payload;
    default:
      return state;
  }
}
export function searchStatus(state = false, action) {
  switch (action.type) {
    case types.IS_SEARCHING:
      return action.payload;
    default:
      return state;
  }
}

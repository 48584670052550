import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import FrontpageInfographic from "./svgComponents/frontpageInfographic";

const JaarverslagenIndexOverview = ({
  homepageData,
  homepageDataIsLoading,
  sectionInView,
}) => {
  const variants = {
    wrapper: {
      initial: {},
      animate: {
        transition: {
          staggerChildren: 0.25,
          delayChildren: 0.5,
        },
      },
    },
    content: {
      initial: {
        opacity: 0,
        // scale: 1.2,
      },
      animate: {
        opacity: 1,
        // scale: 1,
        transition: { duration: 1 },
      },
    },
    list: {
      initial: { opacity: 1 },
      animate: {
        opacity: 1,
        transition: {
          duration: 0.1,
          staggerChildren: 0.1,
          delayChildren: 1.2,
        },
      },
    },
    listitem: {
      initial: { opacity: 0, scale: 1.1, originX: 0 },
      animate: {
        opacity: 1,
        scale: 1,
        transition: { duration: 0.2 },
      },
    },
  };
  return (
    <div>
      {!homepageDataIsLoading && (
        <motion.div
          variants={variants.wrapper}
          initial="initial"
          animate={sectionInView ? "animate" : false}
        >
          <motion.h2
            variants={variants.content}
            dangerouslySetInnerHTML={{
              __html: homepageData.jaarverslagen_titel,
            }}
          />

          <div className="columns">
            <div>
              <div className="introduction">
                <div className="text">
                  <motion.p
                    variants={variants.content}
                    dangerouslySetInnerHTML={{
                      __html: homepageData.jaarverslagen_introductie,
                    }}
                  />
                </div>
                <div className="image">
                  <motion.img
                    variants={variants.content}
                    src="./img/icon_ons_huis_23.png"
                  />
                </div>
              </div>
              <motion.div
                variants={variants.content}
                className="jaarverslag-cat geschillen-commissies"
              >
                <h3>Jaarverslagen geschillencommissies</h3>
                <FrontpageInfographic
                  value1={
                    homepageData.jaarverslagen_geschillencommissies
                      .aangemelde_zaken
                  }
                  value2={
                    homepageData.jaarverslagen_geschillencommissies
                      .behandelde_zaken
                  }
                  value3={
                    homepageData.jaarverslagen_geschillencommissies
                      .eindproducten
                  }
                />
                <motion.div variants={variants.list}>
                  {homepageData.jaarverslagen_geschillencommissies?.buttons &&
                    homepageData.jaarverslagen_geschillencommissies.buttons
                      .length > 0 && (
                      <div className="links">
                        {homepageData.jaarverslagen_geschillencommissies.buttons.map(
                          (button, index) => (
                            <motion.div
                              variants={variants.listitem}
                              key={`geschillencommissie-${index}`}
                              className="link"
                            >
                              <Link
                                to={button.link}
                                style={{ color: button.kleur }}
                              >
                                {button.afbeelding && (
                                  <img
                                    src={button.afbeelding}
                                    alt={`Open ${button.label}`}
                                  />
                                )}
                                {button.label && <label>{button.label}</label>}
                                <ButtonLinkArrow />
                              </Link>
                            </motion.div>
                          )
                        )}
                      </div>
                    )}
                </motion.div>
              </motion.div>
            </div>
            <div>
              <motion.div
                variants={variants.content}
                className="jaarverslag-cat klachtenloketten"
              >
                <h3>Jaarverslagen klachtenloketten</h3>
                <FrontpageInfographic
                  type="klachtenloketten"
                  value1={
                    homepageData.jaarverslagen_klachtenloketten.telefonisch
                  }
                  value2={homepageData.jaarverslagen_klachtenloketten.digitaal}
                  value3={
                    homepageData.jaarverslagen_klachtenloketten
                      .totaal_aantal_meldingen
                  }
                />
                <motion.div variants={variants.list}>
                  {homepageData.jaarverslagen_klachtenloketten?.buttons &&
                    homepageData.jaarverslagen_klachtenloketten.buttons.length >
                      0 && (
                      <div className="links">
                        {homepageData.jaarverslagen_klachtenloketten.buttons.map(
                          (button, index) => (
                            <motion.div
                              variants={variants.listitem}
                              className="link"
                              key={`klachtenloket-${index}`}
                            >
                              <Link
                                to={button.link}
                                style={{ color: button.kleur }}
                              >
                                {button.afbeelding && (
                                  <img
                                    src={button.afbeelding}
                                    alt={`Open ${button.label}`}
                                  />
                                )}
                                {button.label && <label>{button.label}</label>}
                                <ButtonLinkArrow />
                              </Link>
                            </motion.div>
                          )
                        )}
                      </div>
                    )}
                </motion.div>
              </motion.div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};
export default JaarverslagenIndexOverview;

const ButtonLinkArrow = () => {
  return (
    <svg
      width="16"
      height="29"
      viewBox="0 0 16 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="button-link-arrow"
    >
      <path
        d="M0.350098 28.48L14.4101 14.41L0.350098 0.350006"
        stroke="currentColor"
      />
    </svg>
  );
};

export function createArticleLink(article) {
  let path = "/inhoud";

  article.categories.map((term) => (path += `/${term}`));
  if (article.isMainArticle) {
    return `${path}`;
  }

  return `${path}/${article["title-save"]}-${article.id}`;
}

export function createHomeLink() {
  let homeurl = "/";
  let cachedHomeUrl = localStorage.getItem("homepage");
  if (cachedHomeUrl != null) {
    homeurl = cachedHomeUrl;
  }
  return homeurl;
}

export function isIE() {
  var win = window;
  var doc = win.document;
  var input = doc.createElement("input");

  //"!win.ActiveXObject" is evaluated to true in IE11
  if (win.ActiveXObject === undefined) return null;
  if (!win.XMLHttpRequest) return 6;
  if (!doc.querySelector) return 7;
  if (!doc.addEventListener) return 8;
  if (!win.atob) return 9;
  //"!doc.body.dataset" is faster but the body is null when the DOM is not
  //ready. Anyway, an input tag needs to be created to check if IE is being
  //emulated
  if (!input.dataset) return 10;
  return 11;
}

export function sendGoogleAnalyticsPageView(e) {
  if (window.ga && window.ga.getAll) {
    window.ga.getAll()[0].set("page", `jaarverslag-2023${e}`);
    window.ga.getAll()[0].send("pageview");
  }
}

export function sendGoogleAnalyticsEvent(category, action, label, value) {
  if (window.ga && window.ga.getAll) {
    window.ga.getAll()[0].send("event", category, action, label, value);
  }
}
